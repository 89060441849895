import { Router }                           from 'react-router';
import React, { useLayoutEffect, useState } from 'react';

export const HistoryRouter = ({ history, ...props }: any) => {
  const [ state, setState ] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [ history ]);

  return (
    <Router
      { ...props }
      location={ state.location }
      navigationType={ state.action }
      navigator={ history }
    />
  );
};

