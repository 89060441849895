import {ObservableShape} from './ObservableShape';
import {ProjectData} from './ProjectData';
import Pamela from '@revodigital/pamela';
import {DataRefType, ICanvasProps, IDataRef, IMetadata, IRawData, IRawLabelHeader} from '@revodigital/mpes';
import {computed, makeAutoObservable} from 'mobx';
import {PamelaEntitiesProvider} from '../../common/pamela-utils';
import {ShapeTypes} from '../../common/enums';
import {initListeners} from '../../common/listeners';
import shortid from 'shortid';
import {ObservableShapesArray} from '../../ext';
import {getShapeTypeFromId, MLE_CANVAS_ID, redirect} from '../../common/utils';
import {RootPaths} from '../../paths/root.paths';

import {EditorHistory} from '../../common/history/EditorHistory';
import {Decoder} from '@revodigital/mpes/lib';
import {measureUnitToPixel, pixelToMeasureUnit} from '../../common/measure-unit-utils';
import {FeaturesManager} from '../features/common/FeaturesManager';
import {ZOOM_FNAME, ZoomFeature} from '../features/work-area/ZoomFeature';
import {PAN_FNAME, PanFeature} from '../features/work-area/PanFeature';
import {DRAW_AREA_FNAME, DrawingAreaFeature} from '../features/work-area/DrawingAreaFeature';
import {Size2D, sizeOf} from '@revodigital/pamela/lib/common/Size2D';
import {constructObservableShape} from './index';
import {pointOf} from '@revodigital/pamela/lib/common/Point2D';
import {AUTO_SAVE_FNAME, AutoSaveFeature} from '../features/save/AutoSaveFeature';
import {EXPORT_FNAME, ExportFeature} from '../features/mpes-io/ExportFeature';
import {ML_AUTH_FNAME, MyLabelAuthFeature} from '../features/mylabel/auth/MyLabelAuthFeature';
import {RECIPE_SWITCHER_FNAME, RevisionSwitcherFeature} from '../features/mylabel/RevisionSwitcherFeature';
import {SAVE_FNAME, SaveFeature} from '../features/save/SaveFeature';
import {SHAPE_FNAME, ShapesFeature} from '../features/shape/precompiled/feature/ShapesFeature';
import {DATAORG_FNAME, DataOriginFeature} from '../features/data-origin/DataOriginFeature';
import {MLPRC_FNAME, MyLabelPrecompiledFeature} from "../features/shape/precompiled/feature/MyLabelPrecompiledFeature";

export interface StoreInitParams {
    labelHeader: IRawLabelHeader;
    canvasProps: ICanvasProps;
    containerId: string;
    labelId: number;
    metadata?: IMetadata;
    persistedLayers?: Pamela.Layer[];
    dataRefs: IDataRef[];
}

export interface VariablesKeyValues {
    [name: string]: string;
}

export interface InputVariable {
    name: string;
    value: string;
}

export class Editor {
    public pamelaLayer__nonObservable: Pamela.Layer;
    public readonly pamelaStage__nonObservable: Pamela.Stage;
    public readonly pamelaTransformer__nonObservable: Pamela.Transformer;
    public readonly shapes: ObservableShapesArray;
    public readonly history: EditorHistory;
    private _currentShape?: ObservableShape<any>;
    private _features: FeaturesManager<Editor>;

    constructor(initParams: StoreInitParams) {
        this._projectData = new ProjectData(
            initParams.labelHeader,
            initParams.canvasProps,
            initParams.labelId,
            this,
            initParams.metadata,
        );

        this.shapes = new ObservableShapesArray(0);
        const {
            stage,
            layer,
            transformer
        } = PamelaEntitiesProvider.getAll(
            initParams.canvasProps.width,
            initParams.canvasProps.height,
            initParams.containerId
        );

        this.pamelaStage__nonObservable = stage;
        this.pamelaLayer__nonObservable = layer;
        this.pamelaTransformer__nonObservable = transformer;

        // Add features
        this._features = new FeaturesManager([
            ZoomFeature,
            PanFeature,
            DrawingAreaFeature,
            AutoSaveFeature,
            ExportFeature,
            MyLabelAuthFeature,
            RevisionSwitcherFeature,
            SaveFeature,
            ShapesFeature,
            DataOriginFeature,
            MyLabelPrecompiledFeature,
        ], this, initParams);

        if (initParams.persistedLayers?.length)
            this.shapesFeature.populateShapesFromLayers(initParams.persistedLayers);

        this.history = new EditorHistory(this);

        // Init all features
        this._features.initAll();
        this._requiresMLAuth = false;

        // Check mylabel auth credentials
        this.checkMLAuth();

        // Make all observable
        makeAutoObservable(this, undefined, {deep: true});
    }

    private _projectData: ProjectData;

    public get projectData(): ProjectData {
        return this._projectData;
    }

    public set projectData(value: ProjectData) {
        this._projectData = value;
    }

    private _clipboard?: ObservableShape<any>;

    public get clipboard() {
        return this._clipboard;
    }

    private _requiresMLAuth: boolean;

    public get requiresMLAuth() {
        return this._requiresMLAuth;
    }

    public set requiresMLAuth(value) {
        this._requiresMLAuth = value;

        if (!value)
            window.location.reload();
    }

    /**
     * Funzionalità per gestire le origini dati dell'etichetta
     */
    public get dataOriginFeature() {
        return this._features.getByName<DataOriginFeature>(DATAORG_FNAME);
    }

    public get myLabelPrecompiledFeature() {
        return this._features.getByName<MyLabelPrecompiledFeature>(MLPRC_FNAME);
    }

    public get labelSize() {
        return sizeOf(this.projectData.labelWidth, this.projectData.labelHeight);
    }

    /**
     * Funzionalità per gestire l'esportazione dei documenti MPES
     */
    public get exportFeature() {
        return this._features.getByName<ExportFeature>(EXPORT_FNAME);
    }

    /**
     * Funzionalità per gestire le forme
     */
    public get shapesFeature() {
        return this._features.getByName<ShapesFeature>(SHAPE_FNAME);
    }

    /**
     * Recipe switcher
     */
    public get recipeSwitcher() {
        return this._features.getByName<RevisionSwitcherFeature>(
            RECIPE_SWITCHER_FNAME);
    }

    /**
     * Autenticazione ai servizi mylabel
     */
    public get mylabelAuthFeature() {
        return this._features.getByName<MyLabelAuthFeature>(ML_AUTH_FNAME);
    }

    /**
     * Salvataggio delle etichette
     */
    public get saveFeature() {
        return this._features.getByName<SaveFeature>(SAVE_FNAME);
    }

    static fromDecodedDocument(decodedData: IRawData, labelId?: number) {
        return new this({
            labelHeader: decodedData.header,
            canvasProps: decodedData.canvasprops,
            metadata: decodedData.metadata,
            containerId: MLE_CANVAS_ID,
            labelId: labelId ?? -1,
            persistedLayers: decodedData.content?.data,
            dataRefs: decodedData.dataRefs || [],
        });
    }

    public checkMLAuth() {
        this._requiresMLAuth = this.dataOriginFeature.dataRefs.filter(it => it.type === DataRefType.DYNAMIC).length > 0 && !this.mylabelAuthFeature.hasCredentials;
    }

    /**
     * Returns the zoom feature.
     */
    public zoom(): ZoomFeature {
        return this._features.getByName<ZoomFeature>(ZOOM_FNAME);
    }

    /**
     * Returns the pan feature
     */
    public pan(): PanFeature {
        return this._features.getByName<PanFeature>(PAN_FNAME);
    }

    public autoSave(): AutoSaveFeature {
        return this._features.getByName<AutoSaveFeature>(AUTO_SAVE_FNAME);
    }

    /**
     * Returns the drawing area feature
     */
    public drawArea(): DrawingAreaFeature {
        return this._features.getByName<DrawingAreaFeature>(DRAW_AREA_FNAME);
    }

    @computed
    public getSelectedShape<T extends ObservableShape = ObservableShape>(): T {
        return this._currentShape as T;
    }

    public getFilename(extension: string, pixelRatio: number = 1) {
        return `${this.projectData.title.replaceAll(' ',
            '_')}_p0__${Date.now()}_@${pixelRatio}x.${extension}`;
    }

    public getRawFilename(extension: string): string {
        return `${this.projectData.title.replaceAll(' ',
            '_')}_p0__${Date.now()}.${extension}`;
    }

    public getVariablesNames() {
        let ret: Pamela.ExportVariable[] = [];
        this.pamelaStage__nonObservable.getLayers().forEach(layer => ret = [...ret, ...layer.find(
            (shape: any) => shape instanceof Pamela.ExportVariable) as Pamela.ExportVariable[]]);
        return ret.map(variable => variable.variableName());
    }

    public pixelToCurrent(value: number): number {
        return pixelToMeasureUnit(this.projectData.projectOptions.measureUnit,
            value);
    }

    public currentToPixel(value: number): number {
        return measureUnitToPixel(this.projectData.projectOptions.measureUnit,
            value);
    }

    public setLabelSize(size: Size2D) {
        this._projectData.setLabelWidth(size.getWidth());
        this._projectData.setLabelHeight(size.getHeight());
    }

    public initListeners() {
        initListeners(this);
    }

    public hasClipboard() {
        return !!this._clipboard;
    }

    public select(shape: Pamela.Shape) {
        this.deselect();
        this._currentShape = constructObservableShape(shape, this);
        this.pamelaTransformer__nonObservable.nodes([shape]);

        this.pamelaLayer__nonObservable.draw();
    }

    public selectObservable(shape: ObservableShape<any>) {
        this._currentShape = shape;
    }

    public deselect() {
        this._currentShape = undefined;
        this.pamelaTransformer__nonObservable.nodes([]);
    }

    public hasSelection() {
        return !!this._currentShape;
    }

    public async deleteSelectedShape() {
        if (this._currentShape) {
            await this.history.takeSnapshot();
            this.shapesFeature.deleteShape(this._currentShape);
        }
    }

    public async exit(params?: { saveBefore: boolean }) {
        if (params && params.saveBefore)
            await this.saveFeature.encodeAndUpload();

        // Destroy work-area features
        this._features.destroyAll();

        redirect(RootPaths.DASHBOARD);
    }

    public async restore(state: string) {
        const decoder = new Decoder();
        const rawData = await decoder.decodeBuffer(
            state);
        this.shapesFeature.populateShapesFromLayers(rawData.content.data,
            {fromSnapshot: true});
        this.pamelaLayer__nonObservable.draw();

        if (this.hasSelection() && this.shapesFeature.contains(this._currentShape?.pamelaShape__nonObservable as Pamela.Shape))
            this.select(this.shapesFeature.getShapeById(this._currentShape?.pamelaShape__nonObservable.id() as string));
        else this.deselect();
    }

    public clone(shape: Pamela.Shape): Pamela.Shape {
        const clone = shape.clone();
        clone.id(this.generateShapeId(getShapeTypeFromId(clone.id())));
        return clone;
    }

    public copy() {
        if (this._currentShape) {
            this._clipboard = constructObservableShape(this.clone(this._currentShape.pamelaShape__nonObservable),
                this);
            this.history.takeSnapshot();
        }
    }

    public paste(point?: Pamela.Point2D) {
        if (this._clipboard) {
            const scale = this.pamelaStage__nonObservable.scaleX();
            let position = point ? point : pointOf(this._clipboard.x + this.pixelToCurrent(
                15), this._clipboard.y + this.pixelToCurrent(15 * scale));

            this.history.takeSnapshot();
            this._clipboard.setX(position.x / scale,
                {applyToPamelaShape: true});
            this._clipboard.setY(position.y / scale,
                {applyToPamelaShape: true});
            this.pamelaLayer__nonObservable.add(this._clipboard.pamelaShape__nonObservable);
            this.shapes.push(this._clipboard);
            this.pamelaLayer__nonObservable.draw();
            this.select(this._clipboard.pamelaShape__nonObservable);
            this._clipboard = constructObservableShape(this.clone(this._clipboard.pamelaShape__nonObservable),
                this);
        }
    }

    public pasteAtCursorPosition = () => {
        const point = this.getPointerPosition() || pointOf();
        const stagePos = this.pamelaStage__nonObservable.absolutePosition() as Pamela.Vector2d;
        point.incrementX(this.pixelToCurrent(stagePos.x * (-1)) as number);
        point.incrementY(this.pixelToCurrent(stagePos.y * (-1)) as number);
        this.paste(point);
    };

    public getPointerPosition(): Pamela.Point2D {
        if (this.pamelaStage__nonObservable && this.pamelaStage__nonObservable.getPointerPosition()) {
            const pointer = this.pamelaStage__nonObservable.getPointerPosition() as any;
            return pointOf(this.pixelToCurrent(pointer.x),
                this.pixelToCurrent(pointer.y));
        }
        return pointOf();
    }

    public canPaste() {
        return !!this._clipboard;
    }

    public duplicate() {
        this.copy();
        this.paste();
    }

    generateShapeId(shapeTypes: ShapeTypes): string {
        return `mle_${shapeTypes}_${shortid.generate()}`;
    }
}
