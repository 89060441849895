import Pamela              from '@revodigital/pamela';
import { Editor }          from '../../store/observables/Editor';
import { ObservableShape } from '../../store/observables/ObservableShape';

export const updatePositionOnDrag = (editor: Editor, e: Pamela.KonvaEventObject<any>) => {
  if (e.evt.altKey) return;

  if (!editor.hasSelection())
    editor.select(e.target as Pamela.Shape);

  if (editor.hasSelection()) {
    (editor.getSelectedShape()).setX(editor.pixelToCurrent(e.target.x()));
    (editor.getSelectedShape()).setY(editor.pixelToCurrent(e.target.y()));
  }
};
