import Ruler from '@scena/react-ruler';
import {observer} from 'mobx-react';
import {Editor} from '../../../store/observables/Editor';
import {pixelToMeasureUnit} from '../../../common/measure-unit-utils';
import {reaction} from 'mobx';
import {useCallback} from 'react';
import {nearestDecimalOrZero,} from '../../../common/utils';

interface Props {
    editor: Editor;
}

export const VerticalRuler = observer((props: Props) => {
    const panFeature = props.editor.pan();
    const zoomFeature = props.editor.zoom();
    let ruler: Ruler;

    // Resize ruler when measureUnit changes
    reaction(() => props.editor.projectData.projectOptions.measureUnit,
        (value) => {
            if (ruler) {
                ruler.resize();
            }
        });

    const calculateUnit = useCallback(() => {
        const height = props.editor.labelSize.getHeight();

        const unit = nearestDecimalOrZero(
            (height / nearestDecimalOrZero(Math.round(props.editor.currentToPixel(5 / (zoomFeature.zoom))))) / zoomFeature.zoom);

        if (unit == 0)
            return props.editor.currentToPixel(10);

        return props.editor.currentToPixel(unit);
    }, [props.editor.labelSize, zoomFeature.zoom]);


    return (
        <Ruler
            backgroundColor="#EBF1F5"
            lineColor="#A7B6C2"
            textColor="#A7B6C2"
            negativeRuler={true}
            textAlign={'center'}
            segment={10}
            mainLineSize={10}
            shortLineSize={5}
            longLineSize={4}
            type="vertical"
            direction="end"
            style={{
                display: 'block',
                width: '1.8rem',
                position: 'relative',
                top: '1.8rem',
                height: '100%'
            }}
            font="10px sans-serif"
            scrollPos={Math.round(panFeature.position.y * (-1) / zoomFeature.zoom)}
            zoom={zoomFeature.zoom}
            unit={calculateUnit()}
            textFormat={(pos) => (nearestDecimalOrZero(pixelToMeasureUnit(props.editor.projectData.projectOptions.measureUnit,
                pos))).toString()
            }
            ref={(ref) => ruler = ref as Ruler}
        />
    );
});
