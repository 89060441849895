import {MlEditorLayout} from '../layout';
import classNames from 'classnames';
import {currentThemeIsDark} from '../../../common/utils';
import {Button, EditableText, Intent, Navbar, NavbarDivider, NavbarGroup, NavbarHeading} from '@blueprintjs/core';
import {Popover2, Tooltip2} from '@blueprintjs/popover2';
import {NewShapeMenu_} from '../menus/NewShapeMenu_';
import {observer} from 'mobx-react';
import {Editor,} from '../../../store/observables/Editor';
import React, {useEffect, useState} from 'react';
import {SaveIndicator} from '../drawing/SaveIndicator';
import {ZoomControls} from '../drawing/ZoomControls';
import {UserMenu} from '../../common/menus/UserMenu';
import {SupportMenu} from '../../common/menus/SupportMenu';
import {ShortcutsOverlay} from '../../overlay/ShortcutsOverlay';
import {ChangelogOverlay} from '../../common/overlay/ChangelogOverlay';
import {ProjectConfigDialog} from '../dialogs/ProjectConfigDialog';
import {ExitOptionsDialog} from '../../overlay/ExitOptionsDialog';
import {PamelaEntitiesProvider} from '../../../common/pamela-utils';
import {ExportDialog} from '../export/ExportDialog';
import {PanControls} from '../drawing/PanControls';
import {AssetsDialog} from '../dialogs/AssetsDialog';
import {DataOriginDialog} from '../dialogs/data-origin/DataOriginDialog';
import {LinkDataOriginDialog} from '../dialogs/data-origin/LinkDataOriginDialog';
import {PreviewDialog} from '../dialogs/PreviewDialog';
import {PreviewButton} from '../buttons/PreviewButton';
import {MyLabelOriginConfig} from '../dialogs/data-origin/MyLabelOriginConfig';
import {EditorInstanceManager} from '../../../store/observables/EditorInstanceManager';
import {ProjectTitleRegex} from "../../forms/ProjectDataForm";

export interface EditorHeaderProps {
    userEmail: string;
    editor: Editor;
}

export const EditorHeader = observer((props: EditorHeaderProps) => {

    const [projectConfigIsOpen, setProjectConfigIsOpen] = useState(false);
    const [shortcutsIsOpen, setShortcutsIsOpen] = useState(false);
    const [changelogIsOpen, setChangelogIsOpen] = useState(false);
    const [exitOptionsVisible, setExitOptionsVisible] = useState(false);
    const [exportDialogIsOpen, setExportDialogIsOpen] = useState(false);
    const [invalidInput, setInvalidInput] = useState(false);
    const [assetsDialogIsOpen, setAssetsDialogIsOpen] = useState(false);
    const [dataOriginDialogIsOpen, setDODialogIsOpen] = useState(false);
    const [linkOriginIsVisible, setLinkOriginIsVisible] = useState(false);
    const [previewDialogIsVisible, setPreviewDialogIsVisible] = useState(false);
    const [chActRecipeVisible, setChActRecipeVisible] = useState(false);

    useEffect(() => {
        if (invalidInput) {
            setTimeout(() => {
                setInvalidInput(false);
            }, 1000);
        }
    }, [invalidInput]);

    return (
        <>
            <MlEditorLayout.Header
                className={classNames('bp4-fill h-12', {
                    'bp4-dark bg-bpdark-400': currentThemeIsDark(),
                    'bg-bpgray-100': !currentThemeIsDark()
                })}>
                <Navbar>
                    <NavbarGroup>
                        <Tooltip2
                            position="bottom"
                            content="Torna alla dashboard"
                        >
                            <Button
                                intent={Intent.PRIMARY}
                                minimal
                                icon="arrow-left"
                                className="mr-1"
                                onClick={() => setExitOptionsVisible(true)}
                            />
                        </Tooltip2>
                        <NavbarHeading
                            className="font-semibold mr-0">
                            MLEditor
                        </NavbarHeading>
                        <NavbarDivider className="mx-2 px-0"/>
                        <Button
                            icon="media" minimal
                            onClick={() => setAssetsDialogIsOpen(true)}
                        />
                        <Button icon="database" minimal
                                onClick={() => setDODialogIsOpen(true)}/>
                        <Button
                            minimal
                            icon="cloud-download"
                            onClick={() => setExportDialogIsOpen(true)}
                        />
                        <Button
                            icon={'floppy-disk'} minimal
                            onClick={() => props.editor.saveFeature.encodeAndUpload()}
                        />
                    </NavbarGroup>
                    <NavbarGroup className="absolute left-64 space-x-2">
                        {/* TODO: Sostituire con handler funzionante */}
                        <Popover2
                            content={<NewShapeMenu_
                                onAddImage={() => setAssetsDialogIsOpen(true)}
                                editor={props.editor}/>}
                            position="bottom"
                        >
                            <Button intent={Intent.PRIMARY} icon="plus"
                                    rightIcon="caret-down"/>
                        </Popover2>
                        <div className="flex flex-col gap-y-0 ml-5">
                            <strong>
                                <EditableText
                                    intent={invalidInput ? Intent.DANGER : Intent.NONE}
                                    maxLines={1}
                                    multiline={false}
                                    placeholder="Inserisci un titolo"
                                    selectAllOnFocus
                                    confirmOnEnterKey
                                    value={props.editor.projectData.title}
                                    onChange={(newTitle) => {
                                        if (newTitle.match(ProjectTitleRegex))
                                            props.editor.projectData.title = newTitle;
                                        else setInvalidInput(true);
                                    }}
                                    onConfirm={() => {
                                        if (props.editor.projectData.title)
                                            props.editor.saveFeature.encodeAndUpload();
                                        else setInvalidInput(true);
                                    }}
                                />
                            </strong>
                            <div className="bp4-text-muted">
                                <SaveIndicator
                                    editor={props.editor}
                                />
                            </div>
                        </div>
                    </NavbarGroup>
                    <div className={'absolute right-80 space-x-2 flex flex-row'}>
                        <NavbarGroup>
                            <PreviewButton editor={props.editor}
                                           onPreviewRequest={() => setPreviewDialogIsVisible(
                                               true)}
                                           onChangeActive={() => setChActRecipeVisible(true)}
                            />
                        </NavbarGroup>
                        <ZoomControls editor={props.editor}/>
                        <PanControls editor={props.editor}/>
                    </div>
                    <NavbarGroup align="right">
                        <Button
                            minimal
                            icon="settings"
                            onClick={() => setProjectConfigIsOpen(true)}
                        />
                        <Popover2 content={<UserMenu email={props.userEmail}/>}>
                            <Button minimal icon="user"/>
                        </Popover2>
                        <Button
                            minimal
                            icon="key-command"
                            onClick={() => setShortcutsIsOpen(true)}
                        />
                        <NavbarDivider/>
                        <Button
                            minimal
                            icon="automatic-updates"
                            onClick={() => setChangelogIsOpen(true)}
                            small
                        >
                            <div className="bp4-text-muted">Changelog</div>
                        </Button>
                        <NavbarDivider/>
                        <Popover2
                            position="bottom-right"
                            autoFocus={false}
                            content={<SupportMenu/>}
                        >
                            <Button minimal icon="help"/>
                        </Popover2>
                    </NavbarGroup>
                </Navbar>
            </MlEditorLayout.Header>
            <ShortcutsOverlay
                handleClose={() => setShortcutsIsOpen(false)}
                isOpen={shortcutsIsOpen}
            />
            <ChangelogOverlay
                handleClose={() => setChangelogIsOpen(false)}
                isOpen={changelogIsOpen}
            />
            {projectConfigIsOpen && <ProjectConfigDialog
                isOpen={projectConfigIsOpen}
                onClose={() => setProjectConfigIsOpen(false)}
                editor={props.editor}
            />}
            <ExportDialog
                isOpen={exportDialogIsOpen}
                onClose={() => setExportDialogIsOpen(false)}
                editor={props.editor}
            />
            <ExitOptionsDialog
                editor={props.editor}
                onCancel={() => setExitOptionsVisible(false)}
                onSaveAndExit={async () => {
                    // TODO: Riordinare in facade o wrapper
                    await props.editor.exit({saveBefore: true});
                    EditorInstanceManager.destroy();
                    PamelaEntitiesProvider.destroyAll();
                }}
                onExitDiscard={async () => {
                    await props.editor.exit();
                    EditorInstanceManager.destroy();
                    PamelaEntitiesProvider.destroyAll();
                }}
                visible={exitOptionsVisible}
            />
            {assetsDialogIsOpen && <AssetsDialog
                editor={props.editor}
                isOpen={assetsDialogIsOpen}
                onClose={() => setAssetsDialogIsOpen(false)}
            />}
            {dataOriginDialogIsOpen && <DataOriginDialog editor={props.editor}
                                                         isOpen={dataOriginDialogIsOpen}
                                                         onClose={() => setDODialogIsOpen(
                                                             false)}
                                                         onLinkDataOrigin={() => {
                                                             setDODialogIsOpen(false);
                                                             setLinkOriginIsVisible(
                                                                 true);
                                                         }}
            />}
            {
                linkOriginIsVisible &&
                <LinkDataOriginDialog editor={props.editor}
                                      isOpen={linkOriginIsVisible}
                                      onClose={() => setLinkOriginIsVisible(false)}/>
            }
            {
                previewDialogIsVisible &&
                <PreviewDialog editor={props.editor} isOpen={previewDialogIsVisible}
                               onClose={() => setPreviewDialogIsVisible(false)}
                               onConnectRevisions={() => setChActRecipeVisible(true)}
                               onExport={() => {
                                   setExportDialogIsOpen(true);
                                   setPreviewDialogIsVisible(false);
                               }}/>
            }
            {
                chActRecipeVisible && <MyLabelOriginConfig editor={props.editor}
                                                           isOpen={chActRecipeVisible}
                                                           showCloseButton
                                                           onClose={() => setChActRecipeVisible(
                                                               false)}/>
            }
        </>
    );
});
