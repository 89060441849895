import { ObservableShape } from '../store/observables/ObservableShape';

export class ObservableShapesArray extends Array<ObservableShape<any>> {
  replace(newItems: ObservableShape<any>[]) {
    (this as Array<ObservableShape<any>>).splice(0, this.length, ...newItems);
  }
}


declare global {
  interface String {
    truncate(length: number): string;
  }
}

String.prototype.truncate = function (n) {
  return this.substr(0, n - 1) + (this.length > n ? '...' : '');
};
