
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export enum DataOriginType {
    REMOTE = "REMOTE",
    LOCAL = "LOCAL"
}

export enum OriginContentType {
    SQL = "SQL",
    MyLabel = "MyLabel",
    Excel = "Excel"
}

export enum UserRole {
    BASE = "BASE",
    ADMIN = "ADMIN"
}

export interface CompanyInput {
    name: string;
    taxCode: string;
    fiscalCode: string;
    reaCode: string;
    pec: string;
    email: Email;
    address: string;
}

export interface CompanyInputPartial {
    name?: Nullable<string>;
    taxCode?: Nullable<string>;
    fiscalCode?: Nullable<string>;
    reaCode?: Nullable<string>;
    pec?: Nullable<string>;
    email?: Nullable<Email>;
    address?: Nullable<string>;
    adminId?: Nullable<number>;
}

export interface DataOriginInput {
    type: DataOriginType;
    hasCredentials: boolean;
    resource: string;
    name: string;
    contentType: OriginContentType;
    companyId: number;
}

export interface UserInput {
    firstName: string;
    lastName: string;
    phone: string;
    companyId?: Nullable<number>;
    role: UserRole;
}

export interface UserInputPartial {
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    phone?: Nullable<string>;
    companyId?: Nullable<number>;
}

export interface IQuery {
    fetchContent(key: string): string | Promise<string>;
    fetchFile(key: string): File | Promise<File>;
    company(id: number): Nullable<Company> | Promise<Nullable<Company>>;
    companies(): Company[] | Promise<Company[]>;
    dataOrigin(id: number): DataOrigin | Promise<DataOrigin>;
    getAvailableBuiltins(): string[] | Promise<string[]>;
    getBuiltinMetaForLabel(id: number): BuiltinMeta[] | Promise<BuiltinMeta[]>;
    label(id: number): Nullable<Label> | Promise<Nullable<Label>>;
    labels(userId?: Nullable<number>): Label[] | Promise<Label[]>;
    image(id: number): MLEImage | Promise<MLEImage>;
    getMyLabelRecipe(labelId: number, companyId: number): MyLabelRecipe | Promise<MyLabelRecipe>;
    getMyLabelRecipesMeta(companyId: number): Nullable<MyLabelRecipeMeta>[] | Promise<Nullable<MyLabelRecipeMeta>[]>;
    getAvailableRecipeRootKeys(): string[] | Promise<string[]>;
    getAvailableDynamicKeys(revisionId: number, companyId: number): string[] | Promise<string[]>;
    getAvailableDynamicGroups(revisionId: number, companyId: number): DataRefGroup[] | Promise<DataRefGroup[]>;
    getCompaniesMeta(): Nullable<MyLabelCompanyMeta>[] | Promise<Nullable<MyLabelCompanyMeta>[]>;
    getCompanyMeta(companyId: number): MyLabelCompanyMeta | Promise<MyLabelCompanyMeta>;
    getLabelRevisions(companyId: number): MyLabelRevision[] | Promise<MyLabelRevision[]>;
    getLabelRevision(companyId: number, revisionId: number): MyLabelRevision | Promise<MyLabelRevision>;
    user(id: number): Nullable<User> | Promise<Nullable<User>>;
    users(): User[] | Promise<User[]>;
}

export interface IMutation {
    uploadS3(newKey: string, body: string): ManagedUpload | Promise<ManagedUpload>;
    removeS3(key?: Nullable<string>): boolean | Promise<boolean>;
    addCompany(input: CompanyInput): Company | Promise<Company>;
    removeCompany(id: number): Company | Promise<Company>;
    updateCompany(id: number, input: CompanyInputPartial): Company | Promise<Company>;
    addDataOrigin(input: DataOriginInput): DataOrigin | Promise<DataOrigin>;
    removeDataOrigin(id: number): boolean | Promise<boolean>;
    exportLabel(id: number, activeRecipeId?: Nullable<number>, activeCompanyId?: Nullable<number>, vars?: Nullable<string>): string | Promise<string>;
    exportLabelFromDocument(id: number, doc: string, vars?: Nullable<string>): string | Promise<string>;
    addLabel(bodyContent: string, title: string, isTemplate?: Nullable<boolean>): Label | Promise<Label>;
    removeLabel(id: number): Label | Promise<Label>;
    updateLabel(id: number, bodyContent: string, title: string): Label | Promise<Label>;
    saveOnly(id: number): Label | Promise<Label>;
    singleUpload(file: Upload, ACL: ObjectCannedACL): File | Promise<File>;
    uploadImage(image: Upload): File | Promise<File>;
    removeImage(id: number): MLEImage | Promise<MLEImage>;
    mle_login(username: string, password: string): string | Promise<string>;
    computeDynamicOriginValue(key: string, revisionId: number, companyId: number): Nullable<string> | Promise<Nullable<string>>;
    computeDynamicOriginValues(keys: string[], revisionId: number, companyId: number): DataOriginComputeResult[] | Promise<DataOriginComputeResult[]>;
    addUser(input: UserInput): User | Promise<User>;
    removeUser(id: number): User | Promise<User>;
    updateUser(id: number, input: UserInputPartial): User | Promise<User>;
}

export interface ManagedUpload {
    ETag: string;
    Location: string;
    key: string;
    Key: string;
    Bucket: string;
}

export interface Company {
    id: number;
    name: string;
    taxCode: string;
    fiscalCode: string;
    reaCode: string;
    pec: string;
    email: Email;
    address: string;
    users: User[];
    images: MLEImage[];
    admin: User;
    dataOrigins: DataOrigin[];
}

export interface DataOrigin {
    id: number;
    creationDate: number;
    type: DataOriginType;
    hasCredentials: boolean;
    resource: string;
    name: string;
    contentType: OriginContentType;
    company: Company;
}

export interface BuiltinMeta {
    name: string;
    value: string;
}

export interface Label {
    id: number;
    user: User;
    title: string;
    creationDate: number;
    lastModified: number;
    specFileURL: string;
    bodyContent: string;
    version: string;
    isTemplate?: Nullable<boolean>;
}

export interface File {
    filename: string;
    mimetype: string;
    encoding: string;
    Location: string;
}

export interface MLEImage {
    id: number;
    company: Company;
    bucketURL: string;
    content: string;
}

export interface DataOriginComputeResult {
    key: string;
    value?: Nullable<string>;
}

export interface KeyValue {
    key: string;
    value?: Nullable<string>;
}

export interface DataRefGroup {
    groupName: string;
    keys: KeyValue[];
}

export interface MyLabelRecipeMeta {
    id: number;
    name: string;
}

export interface MyLabelCompanyMeta {
    id: number;
    name: string;
}

export interface MyLabelRecipe {
    id: number;
    main: string;
    required: string;
    energy?: Nullable<string>;
    nutrients?: Nullable<string>;
    ingredients?: Nullable<string>;
    allergens?: Nullable<string>;
    portions?: Nullable<string>;
    processing?: Nullable<string>;
    complementary?: Nullable<string>;
    meta?: Nullable<string>;
}

export interface MyLabelRevision {
    id: number;
    company_id: number;
    version_number: number;
    subversion_number: number;
    alternative_version?: Nullable<string>;
    recipe_name: string;
    recipe_code: string;
    last_modified: string;
    creation_date: string;
    unwrapped_data: string;
}

export interface MyLabelRevisionData {
    main_info: string;
    status: string;
    ingredients_list_stmt: string;
    ingredients_list_note_stmt?: Nullable<string>;
    factory_allergens_stmt?: Nullable<string>;
    allergens_stmt?: Nullable<string>;
    complementary_stmts?: Nullable<string>;
    processing_stmt?: Nullable<string>;
    portions_stmt?: Nullable<string>;
    required_stmts?: Nullable<string>;
    energy_stmts?: Nullable<string>;
    nutrient_stmts?: Nullable<string>;
    translations?: Nullable<Nullable<string>[]>;
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    company?: Nullable<Company>;
    role: UserRole;
}

export type Email = any;
export type Upload = any;
export type ObjectCannedACL = any;
type Nullable<T> = T | null;