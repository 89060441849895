import {ControlTypes,} from '../../../common/selection-properties';
import {observer} from 'mobx-react';
import {NumericControlInput} from './NumericControlInput';
import {MeasureUnits} from '../../../common/enums';
import {ButtonGroupShapeControl, ShapeControl} from '../../../common/controls/helpers/ControlGroup';
import {Callout, IconName, Intent} from '@blueprintjs/core';
import {TextBasedControlInput} from './TextBasedControlInput';
import {ButtonGroupControlInput} from './ButtonGroupControlInput';
import {SelectControlInput} from './SelectControlInput';
import {SelectSCItem, SelectShapeControl} from '../../../common/controls/helpers/SelectShapeControl';
import {CheckBoxControlInput} from './CheckBoxControlInput';
import {TextAreaControlInput} from './TextAreaControlInput';
import {ColorControlInput} from './ColorControlInput';
import {AlignmentControlInput} from './AlignmentControlInput';
import {SimpleTextShapeControl} from "../../../common/controls/helpers/SimpleTextShapeControl";
import {HasEditor} from "../../../common/props";


export interface ControlFieldProps<V> {
    observableValue: V;
    setter: (value: V) => any;
    transformer?: ControlFieldTransformer<V>;
}

export interface ChildControlFieldProps<V> extends ControlFieldProps<V> {
    label: string;
    labelInfo?: string;
    leftIcon?: IconName;
    step?: number;
    min?: V;
    max?: V;
    disabled?: boolean;
}

export interface ParentControlFieldProps<V> extends HasEditor {
    shapeControl: ShapeControl<V>;
    currentMeasureUnit?: MeasureUnits;
}

export interface ControlFieldTransformer<V> {
    onDisplay: (displayedValue: V) => V;
    onEnter: (enteredValue: V) => V;
}

export const ShapeControlView = observer((props: ParentControlFieldProps<any>) => {
    switch (props.shapeControl.controlType) {
        case ControlTypes.NUMBER:
            return <NumericControlInput
                label={props.shapeControl.label}
                labelInfo={props.shapeControl.displayMeasureUnit ? props.currentMeasureUnit : props.shapeControl.measureUnitOverride ? props.shapeControl.measureUnitOverride : ''}
                observableValue={props.shapeControl.observableValue as number}
                setter={props.shapeControl.setter}
                transformer={props.shapeControl.transformer}
                min={(props.shapeControl as any).min}
                max={(props.shapeControl as any).max}
                disabled={props.shapeControl.isReadonly}
                step={(props.shapeControl as any).step}
            />;

        case ControlTypes.RICH_TEXT:
            return <TextAreaControlInput label={props.shapeControl.label}
                                         observableValue={props.shapeControl.observableValue}
                                         setter={props.shapeControl.setter}
                                         labelInfo={props.shapeControl.measureUnitOverride}/>;

        case ControlTypes.SIMPLE_TEXT:
            return <TextBasedControlInput
                editor={props.editor}
                label={props.shapeControl.label}
                rightElementRenderer={(props.shapeControl as SimpleTextShapeControl).rightElementRenderer}
                labelInfo={props.shapeControl.displayMeasureUnit ? props.currentMeasureUnit : props.shapeControl.measureUnitOverride ? props.shapeControl.measureUnitOverride : ''}
                observableValue={props.shapeControl.observableValue as string}
                setter={props.shapeControl.setter}
                type="text"
                transformer={props.shapeControl.transformer}
            />;

        case ControlTypes.COLOR:
            return <ColorControlInput
                label={props.shapeControl.label}
                labelInfo={props.shapeControl.displayMeasureUnit ? props.currentMeasureUnit : props.shapeControl.measureUnitOverride ? props.shapeControl.measureUnitOverride : ''}
                observableValue={props.shapeControl.observableValue as string}
                setter={props.shapeControl.setter}
            />;

        case ControlTypes.ALIGN_SELECT:
            return <AlignmentControlInput label={props.shapeControl.label}
                                          labelInfo={props.shapeControl.measureUnitOverride}
                                          observableValue={props.shapeControl.observableValue}
                                          setter={props.shapeControl.setter}
                                          enableJustify={(props.shapeControl as any).enableJustify}
            />;

        case ControlTypes.BUTTON_GROUP:
            return <ButtonGroupControlInput
                label={props.shapeControl.label}
                labelInfo={props.shapeControl.displayMeasureUnit ? props.currentMeasureUnit : props.shapeControl.measureUnitOverride ? props.shapeControl.measureUnitOverride : ''}
                observableValue={props.shapeControl.observableValue}
                setter={props.shapeControl.setter}
                options={(props.shapeControl as ButtonGroupShapeControl<any>).options}
            />;
        case ControlTypes.SELECT:
            return <SelectControlInput label={props.shapeControl.label}
                                       observableValue={props.shapeControl.observableValue}
                                       setter={props.shapeControl.setter}
                                       items={(props.shapeControl as SelectShapeControl).items as SelectSCItem<any>[]}
                                       icon={'switch'}/>;
        case ControlTypes.CHECKBOX:
            return <CheckBoxControlInput label={props.shapeControl.label}
                                         observableValue={props.shapeControl.observableValue}
                                         labelInfo={props.shapeControl.measureUnitOverride}
                                         setter={props.shapeControl.setter}/>;
        default:
            return <
                Callout intent
                            ={
                Intent.WARNING
            }
            >Controllo forma non
                supportato</Callout>;
    }
});
