import { Editor }             from '../../../../store/observables/Editor';
import classNames             from 'classnames';
import { currentThemeIsDark } from '../../../../common/utils';
import {
  ObservableTable
}                             from '../../../../store/observables/ObservableTable';
import { Divider }            from '@blueprintjs/core';

interface TableDetailProps {
  editor: Editor;
}

export const TableDetails = (props: TableDetailProps) => {
  const pamelaTable = props.editor.getSelectedShape<ObservableTable>().pamelaShape__nonObservable;
  return (
    <div>
      <div className="bp4-text-muted mle-info-heading">
        Proprietà
      </div>
      <div className={ classNames('pt-2 pb-2 pr-2 w-40 rounded-sm', {
        'bg-bpdisabled-dark': currentThemeIsDark(),
        'bg-bpdisabled-light': !currentThemeIsDark()
      }) }>
        <div>
          <table
            className="bp4-html-table bp4-small bp4-html-table-bordered bp4-html-table-condensed bp4-html-table-striped bp4-interactive">
            <tr>
              <td><strong>Larghezza</strong></td>
              <td>{ props.editor.getSelectedShape<ObservableTable>().width }{ props.editor.projectData.projectOptions.measureUnit }
              </td>
            </tr>
            <tr>
              <td><strong>Altezza</strong></td>
              <td>{ props.editor.getSelectedShape<ObservableTable>().height }{ props.editor.projectData.projectOptions.measureUnit }
              </td>
            </tr>
            <Divider className="my-2"/>
            {/* TODO: sostituire con  */ }
            <tr>
              <td><strong>Righe</strong></td>
              <td>{ pamelaTable.countRows() }</td>
            </tr>
            <tr>
              <td><strong>Colonne</strong></td>
              <td>{ pamelaTable.countColumns() }</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};
