import { Divider, FormGroup } from '@blueprintjs/core';
import React                  from 'react';

interface LabelProps {
  content: string;
  lockable?: string;
}

interface LabelInfoProps {
  content: string;
}

const Label = (props: LabelProps) => {
  return <div className="w-full flex flex-row justify-start h-1">
    <div className="capitalize">{ props.content }</div>
  </div>;
};

const LabelInfo = (props: LabelInfoProps) => {
  return <div className="-mt-1.5"><br/>{ props.content }</div>;
};

interface ShapeControlWrapperProps {
  label: string;
  labelInfo?: string;
  className?: string;
  children?: any;
  divide?: boolean;
}

export const ShapeControlWrapper = (props: ShapeControlWrapperProps) => {
  return <div>
    {
      props.divide && <Divider />
    }
    <FormGroup
      label={ <Label content={ props.label }/> }
      labelInfo={ <LabelInfo content={ props.labelInfo ?? '' }/> }
      className={ props.className }
    >
      { props.children }
    </FormGroup>
  </div>;
};
