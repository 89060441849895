import { Editor }             from '../../store/observables/Editor';
import { makeAutoObservable } from 'mobx';

export interface Memento<T> {
  state: T;
  date: Date;
}

export class EditorMemento implements Memento<string> {
  private _state: string;
  private _date: Date;

  constructor(state: string, date: Date) {
    this._state = state;
    this._date = date;
  }

  public get date(): Date {
    return this._date;
  }

  public set date(value: Date) {
    this._date = value;
  }

  public get state(): string {
    return this._state;
  }

  public set state(value: string) {
    this._state = value;
  }
}

export class EditorHistory {
  private _mementos: Memento<string>[] = [];
  private _originator: Editor;

  constructor(originator: Editor) {
    this._originator = originator;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get mementos(): Memento<string>[] {
    return this._mementos;
  }

  public async takeSnapshot() {
    const encodedDocument = await this._originator.saveFeature.encode();

    this._mementos.push(new EditorMemento(JSON.stringify(encodedDocument),
      new Date()));
    if (this._mementos.length > 50)
      this._mementos.shift();
  }

  public async undo() {
    if (this._mementos.length)
      await this._originator.restore(this._mementos.pop()?.state as string);
  }
}
