import React, { ChangeEvent, useState } from 'react';
import { Callout, FileInput, Intent }   from '@blueprintjs/core';

interface UploadMpesFileProps {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultFilename?: string;
  hasDataOrigin: boolean;
}

export const UploadMpesFilePanel = (props: UploadMpesFileProps) => {
  const [ filename, setFilename ] = useState('');

  return <div className="p-4">
    <div className="text-xl my-2">File</div>
    <div className="bp4-text-muted mb-2">MLEditor acquisirà automaticamente
      tutte le informazioni richieste per la creazione progetto.
    </div>
    <FileInput
      className={ (props.defaultFilename || filename) && 'bp4-file-input-has-selection' }
      inputProps={ { accept: '.mpes' } }
      text={ props.defaultFilename || filename || 'Seleziona il file...' }
      buttonText="Sfoglia"
      onInputChange={ (e: ChangeEvent<HTMLInputElement>) => {
        props.onFileChange(e);
        setFilename(e.target.files![0].name);
      } }
    />
    { !props.hasDataOrigin &&
      <Callout className="mt-4" intent={ Intent.WARNING }
               title="Istanza MyLabel non collegata"
               icon="warning-sign">
        L'organizzazione non ha collegato un'istanza MyLabel, non sarà quindi
        possibile utilizzare
        eventuali origini dati presenti
        nell'etichetta.
      </Callout> }
  </div>;
};
