import { action, computed, makeObservable, observable } from 'mobx';
import {
  defaultSetterOptions,
  ObservableSetterOptions,
  ObservableShape
}                                                       from './ObservableShape';

export class StrokeProperties {
  @observable
  private _fill: string;
  @observable
  private _width: number;
  @observable
  private _enabled: boolean;
  private readonly _shape: ObservableShape<any>;

  constructor(shape: ObservableShape<any>) {
    if (!shape.pamelaShape__nonObservable.stroke()) {
      shape.pamelaShape__nonObservable.stroke('#000000');
      shape.pamelaShape__nonObservable.strokeWidth(0);
    }

    this._shape = shape;
    this._fill = shape.pamelaShape__nonObservable.stroke();
    this._width = shape.editor.pixelToCurrent(shape.pamelaShape__nonObservable.strokeWidth());
    this._enabled = shape.pamelaShape__nonObservable.strokeEnabled();

    makeObservable(this, undefined, { autoBind: true });
  }

  @computed
  public get fill(): string {
    return this._fill;
  }

  @computed
  public get width(): number {
    return this._width;
  }

  @action
  public setFill(value: string, options: ObservableSetterOptions = defaultSetterOptions) {
    this._fill = value;
    if (options.applyToPamelaShape)
      this._shape.pamelaShape__nonObservable.stroke(value);

    this._shape.editor.history.takeSnapshot();
  }

  @action
  public setWidth(value: number, options: ObservableSetterOptions = defaultSetterOptions) {
    this._width = Math.round(value);
    if (options.applyToPamelaShape)
      this._shape.pamelaShape__nonObservable.strokeWidth(this._shape.editor.currentToPixel(
        value));

    this._shape.editor.history.takeSnapshot();
  }

  @action
  public enable(options: ObservableSetterOptions = defaultSetterOptions) {
    this._enabled = true;
    if (options.applyToPamelaShape)
      this._shape.pamelaShape__nonObservable.strokeEnabled(true);

    this._shape.editor.history.takeSnapshot();
  }

  @action
  public disable(options: ObservableSetterOptions = defaultSetterOptions) {
    this._enabled = false;
    if (options.applyToPamelaShape)
      this._shape.pamelaShape__nonObservable.strokeEnabled(false);

    this._shape.editor.history.takeSnapshot();
  }
}
