import {Editor} from '../../../store/observables/Editor';
import {getOS, MLE_CANVAS_ID, MLE_CANVAS_WRAPPER, withIcons} from '../../../common/utils';
import {VerticalRuler} from '../drawing/VerticalRuler';
import {HorizontalRuler} from '../drawing/HorizontalRuler';
import classNames from 'classnames';
import React, {useState} from 'react';
import {ContextMenu2} from '@blueprintjs/popover2';
import {observer} from 'mobx-react';
import {Menu, MenuDivider, MenuItem} from '@blueprintjs/core';
import {HorizontalAlignment, VerticalAlignment} from '@revodigital/pamela/lib/configuration/Alignment';
import {CombosNames, MleCombos} from '../../../common/mle-combos';
import {OSs} from '../../../common/enums';
import {DevDataOverlay} from '../../overlay/DevDataOverlay';
import {TableEditingDialog} from '../selection-tabs/table/TableEditingDialog';
import {RichTextEditorOverlay} from '../../overlay/RichTextEditorOverlay';
import {ObservableRichText} from '../../../store/observables/ObservableRichText';

interface Props {
    editor?: Editor;
}

export const EditorDrawingArea = observer((props: Props) => {
    const drawFeature = props.editor?.drawArea();
    const [devDataIsOpen, setDevDataIsOpen] = useState(false);
    const [tableEditOpen, setTableEditOpen] = useState(false);
    const [richTextEditOpen, setRichTextEditOpen] = useState(false);

    const alignmentActions = (
        <MenuItem
            text={'Allinea'}
            icon={'alignment-horizontal-center'}
            disabled={!props.editor?.hasSelection()}
        >
            <MenuDivider title={'Orizzontale'}/>
            <MenuItem text={'A sinistra'} icon={'alignment-left'}
                      onClick={() => drawFeature?.alignCurrentShapeHorizontally(
                          HorizontalAlignment.Left)}/>
            <MenuItem text={'Al centro X'}
                      icon={'alignment-vertical-center'}
                      onClick={() => drawFeature?.alignCurrentShapeHorizontally(
                          HorizontalAlignment.Center)}/>
            <MenuItem text={'A destra'}
                      icon={'alignment-right'}
                      onClick={() => drawFeature?.alignCurrentShapeHorizontally(
                          HorizontalAlignment.Right)}/>
            <MenuDivider title={'Verticale'}/>
            <MenuItem text={'In alto'} icon={'alignment-top'}
                      onClick={() => drawFeature?.alignCurrentShapeVertically(
                          VerticalAlignment.Top)}/>
            <MenuItem text={'Al centro Y'}
                      icon={'alignment-horizontal-center'}
                      onClick={() => drawFeature?.alignCurrentShapeVertically(
                          VerticalAlignment.Center)}/>
            <MenuItem text={'In basso'} icon={'alignment-bottom'}
                      onClick={() => drawFeature?.alignCurrentShapeVertically(
                          VerticalAlignment.Bottom)}/>
        </MenuItem>
    );

    const layerActions = (
        <MenuItem text={'Livelli'} disabled={!props.editor?.hasSelection()}
                  icon={'layers'}>
            <MenuItem text={'Porta in primo piano'} icon={'double-chevron-up'}
                      onClick={() => props.editor?.getSelectedShape()?.pamelaShape__nonObservable.moveToTop()}/>
            <MenuItem text={'Porta su'} icon={'chevron-up'}
                      onClick={() => props.editor?.getSelectedShape()?.pamelaShape__nonObservable.moveUp()}/>
            <MenuItem text={'Porta giu'} icon={'chevron-down'}
                      onClick={() => props.editor?.getSelectedShape()?.pamelaShape__nonObservable.moveDown()}/>
            <MenuItem text={'Porta in fondo'} icon={'double-chevron-down'}
                      onClick={() => props.editor?.getSelectedShape()?.pamelaShape__nonObservable.moveToBottom()}/>
        </MenuItem>
    );

    const tableActions = (
        <MenuItem text="Tabelle" disabled={!props.editor?.hasSelection()}
                  icon="panel-table">
            <MenuItem text="Modifica" icon="edit"
                      onClick={() => setTableEditOpen(true)}/>
            <MenuItem text="Esporta" icon="export" onClick={() => {
            }} disabled/>
        </MenuItem>
    );

    const richTextActions = (
        <MenuItem text="Modifica" disabled={!props.editor?.hasSelection()}
                  icon="new-text-box" onClick={() => setRichTextEditOpen(true)}/>
    );

    const contextMenu = <Menu>
        <MenuDivider title="Modifica"/>
        <MenuItem
            disabled={!props.editor?.hasSelection()}
            text="Copia"
            label={withIcons((MleCombos as any)[CombosNames.COPY][getOS() ?? OSs.WINDOWS])}
            icon="clipboard"
            onClick={() => props.editor?.copy()}
        />
        <MenuItem
            disabled={!props.editor?.hasClipboard()}
            text="Incolla"
            label={withIcons((MleCombos as any)[CombosNames.PASTE][getOS() ?? OSs.WINDOWS])}
            icon="bring-data"
            onClick={() => props.editor?.paste()}
        />
        <MenuItem
            disabled={!props.editor?.hasClipboard()}
            text={'Incolla qui'}
            label={withIcons((MleCombos as any)[CombosNames.PASTE_HERE][getOS() ?? OSs.WINDOWS])}
            icon={'bring-data'}
            onClick={props.editor?.pasteAtCursorPosition}
        />
        <MenuItem
            disabled={!props.editor?.hasSelection()}
            text="Duplica"
            label={withIcons((MleCombos as any)[CombosNames.DUPLICATE][getOS() ?? OSs.WINDOWS])}
            icon="duplicate"
            onClick={() => props.editor?.duplicate()}
        />
        <MenuDivider title={'Posiziona'}/>
        {alignmentActions}
        {layerActions}
        {
            props.editor?.hasSelection() && props.editor?.getSelectedShape().isTable() &&
            <div>
                <MenuDivider title="Tabella"/>
                {
                    tableActions
                }
            </div>
        }
        {
            props.editor?.hasSelection() && props.editor?.getSelectedShape().isRichText() &&
            <div>
                <MenuDivider title="Testo multistile"/>
                {
                    richTextActions
                }
            </div>
        }
        <MenuDivider title={'Sviluppatori'}/>
        <MenuItem
            text="Dettagli sviluppatori"
            icon="code-block"
            disabled={!props.editor?.hasSelection()}
            onClick={() => setDevDataIsOpen(true)}
        />
        <MenuDivider/>
        <MenuItem
            disabled={!props.editor?.hasSelection()}
            text="Elimina"
            label={withIcons((MleCombos as any)[CombosNames.DELETE][getOS() ?? OSs.WINDOWS])}
            icon="graph-remove"
            onClick={() => props.editor?.deleteSelectedShape()}
        />
    </Menu>;

    return (
        <div className={'h-full w-full flex-row flex'} style={{zIndex: 0}}
             id={MLE_CANVAS_WRAPPER}>
            {props.editor && <div className={'h-full'}>
                <VerticalRuler editor={props.editor}
                />
            </div>}
            <div className={'w-full'}
                 style={{zIndex: 0}}>
                {props.editor && <HorizontalRuler editor={props.editor}/>}
                <ContextMenu2
                    content={contextMenu}
                    popoverProps={{
                        onClosed: () => {
                            if (props.editor?.getSelectedShape() && props.editor?.getSelectedShape().pamelaShape__nonObservable)
                                props.editor?.select(props.editor?.getSelectedShape().pamelaShape__nonObservable);
                        }
                    }}
                    className={'h-auto'}
                >
                    <div
                        id={MLE_CANVAS_ID}
                        className={classNames(
                            'bg-white border-2 border-bpgray-100 mx-auto',
                            {
                                'hidden': !props.editor
                            })}
                        style={{zIndex: 0}}
                    />
                </ContextMenu2>
            </div>
            {
                props.editor &&
                <DevDataOverlay
                    editor={props.editor}
                    onClose={() => setDevDataIsOpen(false)}
                    isOpen={devDataIsOpen}
                />
            }
            {
                props.editor && tableEditOpen &&
                <TableEditingDialog editor={props.editor} isOpen={tableEditOpen}
                                    onClose={() => setTableEditOpen(false)}/>
            }
            {
                props.editor && richTextEditOpen &&
                <RichTextEditorOverlay editor={props.editor}
                                       isOpen={richTextEditOpen}
                                       onClose={() => setRichTextEditOpen(false)}
                                       setter={(value) => props.editor?.getSelectedShape<ObservableRichText>().setHtmlContent(
                                           value, {applyToPamelaShape: true})}
                                       initialValue={props.editor.getSelectedShape<ObservableRichText>().htmlContent}/>
            }
        </div>
    );
});
