import { Editor }                from '../../../store/observables/Editor';
import { Button, Intent }        from '@blueprintjs/core';
import { useCallback, useState } from 'react';
import {
  ExportErrorsCallout
}                                from '../../common/callout/ExportErrorsCallout';

interface ImageTabProps {
  editor: Editor;
  disabled: boolean;
}

export const MpesTab = (props: ImageTabProps) => {
  const [ isDownloading, setIsDownloading ] = useState(false);
  const [ errored, setErrored ] = useState(false);

  function download(content: string, mimeType: string, filename: string) {
    const a = document.createElement('a'); // Create "a" element
    const blob = new Blob([ content ], { type: mimeType }); // Create a blob (file-like object)
    const url = URL.createObjectURL(blob); // Create an object URL from blob
    a.setAttribute('href', url); // Set "a" element link
    a.setAttribute('download', filename); // Set download filename
    a.click(); // Start downloading
  }

  const handleImageGeneration = useCallback(() => {
    setIsDownloading(true);
    setTimeout(async () => {
      try {
        download(await props.editor.exportFeature.exportToMPESDocument(),
          'text/plain',
          props.editor.getRawFilename('mpes'));
        setErrored(false);
      } catch (e) {
        console.error(e);
        setErrored(true);

        setTimeout(() => {
          setErrored(false);
        }, 30000);
      }
      setIsDownloading(false);
    }, 100);
  }, [ setIsDownloading, props.editor.exportFeature, setErrored ]);

  return <div className="mle-project-config-tab">
    {
      errored && <ExportErrorsCallout className="mb-2"/>
    }
    <Button
      loading={ isDownloading }
      icon="label"
      disabled={ isDownloading || errored }
      intent={ Intent.PRIMARY }
      onClick={ handleImageGeneration }>
      Scarica file MPES
    </Button>
    <div className="mt-1.5">
      <small className="bp4-text-muted">Potrai usarlo per importare il progetto
        in altre istanze MLEditor in qualsiasi momento. Nel caso il profilo di
        destinazione non possieda un collegamento a MyLabel, eventuali campi
        dinamici dell'etichetta non saranno disponibili.</small>
    </div>
  </div>;
};
