import { ChildControlFieldProps }                from './ShapeControlView';
import { ShapeControlWrapper }                   from './ShapeControlWrapper';
import { Button, ButtonGroup, IconName, Intent } from '@blueprintjs/core';

export interface ButtonGroupAction<T> {
  icon: IconName;
  value: T;
  name?: string;
}

export const ButtonGroupControlInput = <T extends ButtonGroupAction<T>>(props: ChildControlFieldProps<T> & { options: ButtonGroupAction<T>[] }) => {
  return (
    <ShapeControlWrapper label={ props.label } labelInfo={ props.labelInfo }>
      <ButtonGroup>
        { props.options.map(option => <Button
            intent={ (props.observableValue === option.value || (typeof props.observableValue === 'string' && (props.observableValue as unknown as string)?.includes(
              option.value as unknown as string))) ? Intent.PRIMARY : Intent.NONE }
            small
            icon={ option.icon }
            onClick={ () => props.setter(option.value) }
          />
        ) }
      </ButtonGroup>
    </ShapeControlWrapper>
  );
};
