import { Editor }          from '../store/observables/Editor';
import React               from 'react';
import { HotkeysTarget2 }  from '@blueprintjs/core';
import { MlEditorLayout }  from '../components/editor/layout';
import { getHotkeys }      from '../common/hotkeys';
import { EditorHeader }    from '../components/editor/main/EditorHeader';
import { useSelector }     from 'react-redux';
import { RootState }       from '../store';
import { EditorContent }   from '../components/editor/main/EditorContent';
import { EditorFooter }    from '../components/editor/main/EditorFooter';
import { MinWidthOverlay } from '../components/common/overlay/MinWidthOverlay';

interface EditorContentProps {
  editor?: Editor;
}

export const EditorView = (props: EditorContentProps) => {
  const authData = useSelector((state: RootState) => state.authState);

  return (
    <div>
      <HotkeysTarget2 hotkeys={ getHotkeys({
        onSave: () => props.editor?.saveFeature.encodeAndUpload(),
        onDelete: () => props.editor?.deleteSelectedShape(),
        onUndo: () => props.editor?.history?.undo(),
        onCopy: () => props.editor?.copy(),
        onPaste: () => props.editor?.paste(),
        onPasteHere: () => props.editor?.pasteAtCursorPosition(),
        onDuplicate: () => props.editor?.duplicate(),
      }) }>
        <MlEditorLayout.Root>
          <MlEditorLayout.Wrapper>
            {
              props.editor &&
              <EditorHeader userEmail={ authData.email }
                            editor={ props.editor }/>
            }
            <EditorContent editor={ props.editor }/>
            {
              props.editor &&
              <EditorFooter labelId={ props.editor.projectData.labelId }
                            editor={ props.editor }/>
            }
          </MlEditorLayout.Wrapper>
          <MinWidthOverlay/>
        </MlEditorLayout.Root>
      </HotkeysTarget2>
    </div>
  );
};
