import { observer }               from 'mobx-react';
import { ChildControlFieldProps } from './ShapeControlView';
import { NumericInput }           from '@blueprintjs/core';
import { ShapeControlWrapper }    from './ShapeControlWrapper';

export const NumericControlInput = observer((props: ChildControlFieldProps<number>) => {
  return <ShapeControlWrapper
    label={ props.label }
    labelInfo={ props.labelInfo }
  >
    <NumericInput
      disabled={ props.disabled }
      leftIcon={ props.leftIcon }
      className="bp4-fill min-w-1/2"
      min={ props.min }
      max={ props.max }
      onValueChange={ (value) => {
        props.setter(value);
      } }
      value={ props.observableValue || 0 }
      stepSize={ props.step || 1 }
    />
  </ShapeControlWrapper>;
});
