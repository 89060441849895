import {ColorModes, MeasureUnits, MetadataGroupKeys, MetadataKeys} from '../../common/enums';
import {makeAutoObservable} from 'mobx';
import {ICanvasProps, IMetadata, IRawLabelHeader} from '@revodigital/mpes';
import {Metadata} from '@revodigital/mpes/lib';
import {defaultSetterOptions, ObservableSetterOptions} from './ObservableShape';
import {Editor} from './Editor';
import {measureUnitToMeasureUnit, pixelToMeasureUnit} from '../../common/measure-unit-utils';
import {Size2D} from "@revodigital/pamela/lib/common/Size2D";

export class ProjectOptions {
    constructor(canvasProps?: ICanvasProps, metadata?: IMetadata) {
        const m = new Metadata(metadata);
        if (canvasProps && metadata?.groups) {
            this._measureUnit = m.getKeyValueOfGroup<MeasureUnits>(
                MetadataGroupKeys.TEXT_BASED,
                MetadataKeys.MEASURE_UNIT) || MeasureUnits.PX;
            this._useSmartGuides = m.getKeyValueOfGroup<boolean>(
                MetadataGroupKeys.BOOLEANS,
                MetadataKeys.USE_SMART_GUIDES) || true;
        }

        this._metadata = metadata;
        makeAutoObservable(this);
    }

    private _measureUnit: MeasureUnits = MeasureUnits.PX;

    public get measureUnit(): MeasureUnits {
        return this._measureUnit;
    }

    public set measureUnit(value: MeasureUnits) {
        this._measureUnit = value;
    }

    private _colorMode: ColorModes = ColorModes.COLORE;

    public get colorMode(): ColorModes {
        return this._colorMode;
    }

    public set colorMode(value: ColorModes) {
        this._colorMode = value;
    }

    private _useSmartGuides: boolean = true;

    public get useSmartGuides(): boolean {
        return this._useSmartGuides;
    }

    public set useSmartGuides(value: boolean) {
        this._useSmartGuides = value;
    }

    private _metadata?: IMetadata;

    public get metadata() {
        return this._metadata;
    }
}

export class ProjectData {
    private readonly _editor: Editor;

    constructor(header: IRawLabelHeader, canvasProps: ICanvasProps, labelId: number, editor: Editor, metadata?: IMetadata) {
        this._title = header.title;
        this._labelWidth = canvasProps.width;
        this._labelHeight = canvasProps.height;
        this._labelId = labelId;
        this._editedAt = new Date();
        this._projectOptions = new ProjectOptions(canvasProps, metadata);
        this._editor = editor;

        makeAutoObservable(this, undefined, {deep: true});
    }

    private _title: string;

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    private _labelWidth: number;

    public get labelWidth(): number {
        return this._labelWidth;
    }

    private _labelHeight: number;

    public get labelHeight(): number {
        return this._labelHeight;
    }

    private _editedAt: Date;

    public get editedAt(): Date {
        return this._editedAt;
    }

    public set editedAt(value: Date) {
        this._editedAt = value;
    }

    private _projectOptions: ProjectOptions;

    public get projectOptions(): ProjectOptions {
        return this._projectOptions;
    }

    public set projectOptions(value: ProjectOptions) {
        this._projectOptions = value;
    }

    private _labelId: number;

    public get labelId(): number {
        return this._labelId;
    }

    public set labelId(value: number) {
        this._labelId = value;
    }

    public setLabelWidth(value: number, options: ObservableSetterOptions = defaultSetterOptions) {
        value = measureUnitToMeasureUnit(this._editor.projectData.projectOptions.measureUnit,
            MeasureUnits.PX,
            value);
        this._labelWidth = value;

        if (options.applyToPamelaShape)
            this._editor.pamelaStage__nonObservable.width(value);
    }

    public setLabelHeight(value: number, options: ObservableSetterOptions = defaultSetterOptions) {
        value = measureUnitToMeasureUnit(this._editor.projectData.projectOptions.measureUnit,
            MeasureUnits.PX,
            value);
        this._labelHeight = value;

        if (options.applyToPamelaShape)
            this._editor.pamelaStage__nonObservable.height(value);
    }

    getSizeWithUm(measureUnit: MeasureUnits): Size2D {
        return Size2D.fromBounds(pixelToMeasureUnit(measureUnit, this.labelWidth), pixelToMeasureUnit(measureUnit, this.labelHeight),)
    }
}
