import {ControlGroup, ControlGroupLayouts} from '../../../common/controls/helpers/ControlGroup';
import {Editor} from '../../../store/observables/Editor';
import React from 'react';
import {ShapeControlView} from './ShapeControlView';
import {Callout, Divider, Intent} from '@blueprintjs/core';
import {observer} from "mobx-react";

interface ControlFieldInputWrapperProps {
    editor: Editor;
    controlGroup: ControlGroup;
    dividers?: { top: boolean; bottom: boolean; };
}

export const ControlGroupView = observer((props: ControlFieldInputWrapperProps) => {
    const mappedControls = () => props.controlGroup.shapeControls.map((shapeControl) => {
            if ((!shapeControl.controlledShapesOverride && props.controlGroup.controls(
                props.editor.getSelectedShape())) || shapeControl.overrideControls(props.editor.getSelectedShape()))
                return <ShapeControlView
                    editor={props.editor}
                    shapeControl={shapeControl}
                    currentMeasureUnit={props.editor.projectData.projectOptions.measureUnit}
                />;
        }
    );

    const renderWithLayout = () => {
        switch (props.controlGroup.layout) {
            case ControlGroupLayouts.FLEX_ROW:
                return <div className="mle-form-row">
                    {mappedControls()}
                </div>;

            case ControlGroupLayouts.WHOLE_ROW:
                return <span>
          {props.controlGroup.infoCallout &&
              <Callout
                  className="mb-2"
                  title={props.controlGroup.infoCallout.title}
                  intent={Intent.PRIMARY}
              >
                  {props.controlGroup.infoCallout.description}
              </Callout>}
                    {mappedControls()}
        </span>;

            default:
                return <Callout intent={Intent.WARNING}> Layout non
                    gestito </Callout>;
        }
    };

    if (props.controlGroup.controls(props.editor.getSelectedShape(),
        {includeOverrides: true}))
        return (
            <>
                {props.dividers?.top && <Divider className="mt-2 mb-1"/>}
                <div className="bp4-text-muted mle-info-heading">
                    {props.controlGroup.title}
                </div>
                {renderWithLayout()}
                {props.dividers?.bottom && <Divider className="mb-2 mb-1"/>}
            </>
        );
    return null;
});
