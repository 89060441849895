import { Button, Divider } from '@blueprintjs/core';
import { RootPaths }       from '../paths/root.paths';
import { redirect }        from '../common/utils';

export const LabelNotFound = () => {
  return (
    (
      <div className="mle-center-both-axes-parent">
        <div
          className="mle-center-both-axes-child w-96 bg-white bg-opacity-10 rounded-sm p-4">
          <h4 className="bp4-heading">Progetto inesistente</h4>
          <p>Non abbiamo trovato nessun progetto per questo identificativo, <a
            href="mailto:editor@mylabel.it">contattaci</a> se ritieni ci sia un
            problema.</p>
          <Divider className="my-2"/>
          <Button
            intent="primary"
            className="w-full"
            onClick={ () => redirect(RootPaths.DASHBOARD) }
          >
            Torna alla dashboard
          </Button>
        </div>
      </div>
    )
  );
};
