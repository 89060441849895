import { MlEditorLayout }            from '../layout';
import classNames                    from 'classnames';
import { currentThemeIsDark, getOS } from '../../../common/utils';
import { useEffect, useState }       from 'react';
import { OctokitProvider }           from '../../../common/octokit-provider';
import { Spinner }                   from '@blueprintjs/core';
import * as config                   from '../../../config/defaults.json';
import { Editor }                    from '../../../store/observables/Editor';
import { observer }                  from 'mobx-react';
import { OSs }                       from '../../../common/enums';

export interface EditorFooterProps {
  labelId: number;
  editor: Editor;
}

export const EditorFooter = observer((props: EditorFooterProps) => {
  const [latestVersion, setLatestVersion] = useState('');

  useEffect(() => {
    (async () => {
      const ghRes = await OctokitProvider.getInstance().rest.repos.getLatestRelease(
        {
          owner: config.octoKit.clientOrg,
          repo: config.octoKit.clientRepo
        });
      setLatestVersion(ghRes.data.tag_name);
    })();
  });

  const divider = <span
    className="text-white text-opacity-20"> - </span>;

  return (
    <MlEditorLayout.Footer
      className={ classNames('bp4-fill h-5', {
        'bp4-dark bg-bpdark-400': currentThemeIsDark(),
        'bg-bpgray-100': !currentThemeIsDark()
      }) }>
      <div
        className="flex flex-row justify-between mx-2 text-xs mt-0.5">
        <div className="bp4-text-muted">
          &copy; { new Date().getFullYear() } Datafood srl { divider }
          ID Etichetta <span
          className="font-mono">{ props.labelId }</span> { divider } <span>Progetto in { props.editor.projectData.projectOptions.measureUnit.toUpperCase() }</span>
        </div>
        <div className="bp4-text-muted flex flex-row mx-2">
          <div
            onClick={ () => (window as any).Marker.capture('fullscreen') }
            className="bp4-text-muted cursor-pointer hover:text-opacity-100 hover:bg-bpdark-200 text-xs px-2 mx-2"
          >{
            getOS() === OSs.MAC ? <span>&#8984;</span> : 'alt'
          } + U per feedback
          </div>
          {
            latestVersion ? latestVersion : <Spinner size={ 15 }/>
          }
        </div>
      </div>
    </MlEditorLayout.Footer>
  );
});
