import {Editor, VariablesKeyValues} from '../../../store/observables/Editor';
import {Button, FormGroup, Intent, NumericInput} from '@blueprintjs/core';
import {objectToSelectItemsArray} from '../../../common/utils';
import {useCallback, useState} from 'react';
import {objectToMap} from '../../../common/array';
import {ExportErrorsCallout} from '../../common/callout/ExportErrorsCallout';
import {MleCustomSelect} from "../select/common/MleCustomSelect";

interface ImageTabProps {
    editor: Editor;
    variableKeyValues?: VariablesKeyValues;
    disabled: boolean;
}

enum ImageTypes {
    PNG = 'png',
    JPEG = 'jpeg'
}

export const ImageTab = (props: ImageTabProps) => {
    const [imageType, setImageType] = useState(ImageTypes.PNG);
    const [pixelRatio, setPixelRatio] = useState(1);
    const [isDownloading, setIsDownloading] = useState(false);
    const [errored, setErrored] = useState(false);

    const download = useCallback((uri: string) => {
        const link = document.createElement('a');
        link.download = props.editor.getFilename(imageType, pixelRatio);
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    }, [setIsDownloading]);

    const handleImageGeneration = useCallback(() => {
        const varMap = objectToMap(props.variableKeyValues as Object);
        setIsDownloading(true);
        setTimeout(async () => {
            try {
                download(await props.editor.exportFeature.exportToImage(
                    pixelRatio,
                    varMap));
                setErrored(false);
            } catch (e) {
                console.error("Impossibile esportare immagine: ", e);
                setErrored(true);

                setTimeout(() => {
                    setErrored(false);
                }, 30000);
            }
            setIsDownloading(false);
        }, 100);
    }, [setIsDownloading, props.editor.exportFeature, setErrored, pixelRatio, props.variableKeyValues]);

    return <div className="mle-project-config-tab">
        {
            errored && <ExportErrorsCallout className="mb-2"/>
        }
        <FormGroup
            label="Formato"
            className="mb-3"
        >
            <MleCustomSelect<any>
                initalValue={objectToSelectItemsArray(ImageTypes)[0]}
                items={objectToSelectItemsArray(ImageTypes)}
                name="imageType"
                icon="media"
                renderLabel={item => `.${item.value}`}
                renderText={item => item.name}
                onSelect={item => setImageType(item.value)}
                itemDisabled={item => item.value === ImageTypes.JPEG}
                className="mb-2"
            />
        </FormGroup>
        <FormGroup
            label="Moltiplicatore risoluzione"
            helperText={<span>MLEditor gestisce vettorialmente gli elementi sul
        piano di lavoro e li elabora in fase di esportazione. Dimensioni
        finali: <strong
                    className="text-bpblue-200">{props.editor.projectData.labelWidth * pixelRatio}x{props.editor.projectData.labelHeight * pixelRatio}px</strong></span>}
        >
            <NumericInput
                className="w-1/2"
                value={pixelRatio}
                onValueChange={valueAsNumber => valueAsNumber >= 1 && valueAsNumber < 10 ? setPixelRatio(
                    valueAsNumber) : null}
                min={1}
                max={9}
            />
        </FormGroup>
        <Button
            loading={isDownloading}
            icon="media"
            disabled={isDownloading || errored}
            intent={Intent.PRIMARY}
            onClick={handleImageGeneration}>
            Scarica immagine {imageType.toUpperCase()}
        </Button>
    </div>;
};
