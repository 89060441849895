import classNames from 'classnames';
import {currentThemeIsDark} from '../../../common/utils';
import {Button, Classes, Collapse, Dialog, Divider, Icon, Intent, Tab, Tabs, Tag} from '@blueprintjs/core';
import {Editor, InputVariable, VariablesKeyValues} from '../../../store/observables/Editor';
import {ComingSoonCallout} from '../../common/callout/ComingSoonCallout';
import React, {useCallback, useEffect, useState} from 'react';
import {ImageTab} from './ImageTab';
import {PdfTab} from './PdfTab';
import {VarInput} from '../../VarInput';
import {MpesTab} from './MpesTab';

export interface ExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  editor: Editor;
}

enum ActiveTabIds {
  PDF = 'pdf',
  IMAGE = 'immagine',
  MPES = 'mpes'
}

export const ExportDialog = (props: ExportDialogProps) => {
  const [ activeTabId, setActiveTabId ] = useState(ActiveTabIds.IMAGE);
  const [ variablesKeyValues, setVariablesKeyValues ] = useState<VariablesKeyValues>(
    {});
  const [ varCollapseOpen, setVarCollapseOpen ] = useState(false);


  useEffect(() => {
    let obj = {} as VariablesKeyValues;
    props.editor.getVariablesNames().forEach(variableName => obj[variableName] = '');
    setVariablesKeyValues(obj);
  }, [ props.editor, props.editor.shapes.length ]);

  const applyVariableValue = useCallback((variable: InputVariable) => {
    setVariablesKeyValues({
      ...variablesKeyValues,
      [variable.name]: variable.value
    });
  }, [variablesKeyValues, setVariablesKeyValues]);

  const getEmptyVars = () => {
    let ret = 0;
    Object.keys(variablesKeyValues).forEach(variableName => !(variablesKeyValues[variableName]) ? ret++ : null);
    return ret;
  };

  const variablesSettings = (
    <div className={ classNames('my-3 p-2 rounded', {
      'bg-bpdisabled-dark': currentThemeIsDark(),
      'bg-bpdisabled-light': !currentThemeIsDark()
    }) }>
      <div className="bp4-text-muted mle-info-heading">
        Configura variabili esportazione
      </div>
      {
        props.editor.getVariablesNames().length
        ?
        props.editor.getVariablesNames().map(variableName =>
          <VarInput
            key={ variableName }
            inputVariable={ {
              name: variableName,
              value: variablesKeyValues[variableName] ?? ''
            } }
            onConfirm={ applyVariableValue }
          />)
        : <div>Non sono presenti variabili esportazione nell'etichetta</div>
      }
    </div>
  );

  const varsTag = () => {
    if (getEmptyVars())
      return (
        <Tag minimal className="ml-2"
             intent={ Intent.WARNING }>{ getEmptyVars() } da
          valorizzare</Tag>
      );
    return (
      <Tag minimal className="ml-2" intent={ Intent.SUCCESS }>Valorizzate</Tag>
    );
  };

  const variablesCollapse = (
    <div>
      <Button
        icon={ varCollapseOpen ? 'caret-up' : 'caret-down' }
        rightIcon="variable"
        small
        onClick={ () => {
          setVarCollapseOpen(!varCollapseOpen);
        } }
      >
        Variabili
      </Button>
      { varsTag() }
      <Collapse isOpen={ varCollapseOpen } keepChildrenMounted={ true }>
        { variablesSettings }
      </Collapse>
      <Divider className="my-2"/>
    </div>
  );

  return (
    <Dialog
      canOutsideClickClose={ false }
      usePortal
      className={ classNames({ 'bp4-dark': currentThemeIsDark() }) }
      style={ { width: '40rem' } }
      isOpen={ props.isOpen }
      title="Esportazione"
      onClose={ props.onClose }
    >
      <div className={ Classes.DIALOG_BODY }>
        <ComingSoonCallout
          nextFeatures={ [ 'Configurazine per foglio di stampa con più etichette', 'Metodo margine "includi"' ] }/>
        { variablesCollapse }
        <div className="bp4-text-muted mle-info-heading">
          Tipo esportazione
        </div>
        <Tabs
          vertical
          animate
          selectedTabId={ activeTabId }
          onChange={ newTabId => setActiveTabId(newTabId as ActiveTabIds) }
        >
          <Tab
            className="outline-none"
            id={ ActiveTabIds.IMAGE }
            title={ <div className="capitalize">
              <Icon size={ 12 } icon="media"
                    className="bp4-text-muted mr-1.5 mb-0.5"/>
              { ActiveTabIds.IMAGE }
            </div> }
            panel={ <ImageTab
              editor={ props.editor }
              variableKeyValues={ variablesKeyValues }
              disabled={ !getEmptyVars() }
            /> }
          />
          <Tab
            className="outline-none"
            id={ ActiveTabIds.PDF }
            title={ <div className="capitalize">
              <Icon size={ 12 } icon="document-open"
                    className="bp4-text-muted mr-1.5 mb-0.5"/>
              { ActiveTabIds.PDF }
            </div> }
            panel={ <PdfTab
              editor={ props.editor }
              variableKeyValues={ variablesKeyValues }
              disabled={ !getEmptyVars() }
            /> }
          />
          <Tab
            className="outline-none"
            id={ ActiveTabIds.MPES }
            title={ <div className="capitalize">
              <Icon size={ 12 } icon="label"
                    className="bp4-text-muted mr-1.5 mb-0.5"/>
              { ActiveTabIds.MPES }
            </div> }
            panel={ <MpesTab
              editor={ props.editor }
              disabled={ !getEmptyVars() }
            /> }
          />
        </Tabs>
      </div>
    </Dialog>
  );
};
