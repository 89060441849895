import Pamela          from '@revodigital/pamela';
import { Editor }      from '../../store/observables/Editor';
import { OBJECT_NAME } from '../pamela-names';

export const SELECT_MOUSE_BTN = 0;

export const updateSelectionOnMouseDown = (editor: Editor, stage: Pamela.Stage, tr: Pamela.Transformer, e: Pamela.KonvaEventObject<any>) => {
  // Enable only left button for selecting
  if (e.evt.button !== SELECT_MOUSE_BTN || e.evt.altKey) return;

  if (e.target === stage)
    return editor.deselect();

  if (!e.target)
    editor.deselect();
  else if (!e.target.name().includes('_anchor'))
    editor.select(e.target as Pamela.Shape);
};

// TODO: Refactor using Feature pattern
export const applySelectionRectListeners = (layer: Pamela.Layer, stage: Pamela.Stage, tr: Pamela.Transformer) => {
  const selectionRectangle = new Pamela.Rect({
    name: 'selection-rect',
    fill: 'rgba(72,175,240,0.35)',
    visible: false,
    stroke: '#2B95D6',
    perfectDrawEnabled: false
  });
  layer.add(selectionRectangle);

  let x1: any, y1: any, x2: any, y2: any;
  stage.on('mousedown touchstart', (e) => {
    if (e.evt.button !== SELECT_MOUSE_BTN) return;

    // Bring selection rectangle to top
    layer.bringToTopByName('selection-rect');

    if (e.target !== stage) {
      return;
    }
    e.evt.preventDefault();
    x1 = stage.getRelativePointerPosition()?.x;
    y1 = stage.getRelativePointerPosition()?.y;
    x2 = stage.getRelativePointerPosition()?.x;
    y2 = stage.getRelativePointerPosition()?.y;

    selectionRectangle.visible(true);
    selectionRectangle.width(0);
    selectionRectangle.height(0);
  });

  stage.on('mousemove touchmove', (e) => {
    if (e.evt.button !== SELECT_MOUSE_BTN) return;

    if (!selectionRectangle.visible()) {
      return;
    }
    e.evt.preventDefault();
    x2 = stage.getRelativePointerPosition()?.x;
    y2 = stage.getRelativePointerPosition()?.y;

    selectionRectangle.setAttrs({
      x: Math.min(x1, x2),
      y: Math.min(y1, y2),
      width: Math.abs(x2 - x1),
      height: Math.abs(y2 - y1),
    });
  });

  stage.on('mouseup touchend', (e) => {
    if (e.evt.button !== SELECT_MOUSE_BTN) {
      e.evt.preventDefault();
      return;
    }

    if (!selectionRectangle.visible()) {
      return;
    }
    e.evt.preventDefault();
    setTimeout(() => {
      selectionRectangle.visible(false);
    });

    const shapes = stage.find(`.${ OBJECT_NAME }`);
    const box = selectionRectangle.getClientRect();
    const selected = shapes.filter((shape) =>
      Pamela.Util.haveIntersection(box, shape.getClientRect())
    );
    tr.nodes(selected);
  });
};

export const checkSelectionAvailability = (stage: Pamela.Stage, tr: Pamela.Transformer, e: Pamela.KonvaEventObject<any>) => {
  if (e.evt.button !== SELECT_MOUSE_BTN) return;

  if (e.target === stage) {
    tr.nodes([]);
    return;
  }
  if (!e.target.hasName('object')) {
    return;
  }

  const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
  const isSelected = tr.nodes().indexOf(e.target) >= 0;

  if (!metaPressed && !isSelected) {
    tr.nodes([ e.target ]);
  } else if (metaPressed && isSelected) {
    const nodes = tr.nodes().slice();
    nodes.splice(nodes.indexOf(e.target), 1);
    tr.nodes(nodes);
  } else if (metaPressed && !isSelected) {
    const nodes = tr.nodes().concat([ e.target ]);
    tr.nodes(nodes);
  }
};
