import {Editor, VariablesKeyValues} from '../../../store/observables/Editor';
import {Button, Collapse, FormGroup, Icon, Intent, NumericInput} from '@blueprintjs/core';
import {objectToMap} from '../../../common/array';
import {useCallback, useEffect, useState} from 'react';
import {ExportErrorsCallout} from '../../common/callout/ExportErrorsCallout';
import {MleCustomSelect} from "../select/common/MleCustomSelect";
import {NameValue} from "../../../common/utils";
import {IsoSizes, isoSizesPixelDimensions, isoSizesWithUm} from "../../../common/project";
import {ISOSizeSelect} from "../select/project/ISOSizeSelect";
import {ProjectSizeLabel} from "../../common/ProjectSizeLabel";
import {pixelToMeasureUnit} from "../../../common/measure-unit-utils";
import {SeriesDetails} from "../../../store/features/mpes-io/ExportFeature";
import {SeriesDetailThumbinail} from "./SeriesDetail";

interface PdfTabProps {
    editor: Editor;
    variableKeyValues?: VariablesKeyValues;
    disabled: boolean;
}

export const PdfQualityList = {
    HIGH: {
        name: "Alta",
        value: "high"
    } as NameValue,
    LOW: {
        name: "Bassa",
        value: "low"
    } as NameValue
}

export const PdfTab = (props: PdfTabProps) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [errored, setErrored] = useState(false);
    const [pixelRatio, setPixelRatio] = useState(0);
    const [enableSeries, setEnableSeries] = useState(false);
    const [pageSize, setPageSize] = useState<NameValue>({name: IsoSizes.A4, value: IsoSizes.A4})
    const [pageCount, setPageCount] = useState(1);
    const [margin, setMargin] = useState(0);
    const [invalidSize, setInvalidSize] = useState(false);
    const [seriesAvailable, setSeriesAvailable] = useState(false);
    const [pageMargin, setPageMargin] = useState(5);

    const generateAndDownloadSeries = useCallback(async () => {
        try {
            setIsDownloading(true);
            const varMap = objectToMap(props.variableKeyValues as Object);
            const mu = props.editor.projectData.projectOptions.measureUnit;
            const pageWidth = pixelToMeasureUnit(mu, isoSizesPixelDimensions[(pageSize as any).name].width);
            const pageHeight = pixelToMeasureUnit(mu, isoSizesPixelDimensions[(pageSize as any).name].height);

            await props.editor.exportFeature.generatePdfSeries({
                    fileName:
                        props.editor.getFilename(
                            'pdf'),
                    variables: varMap, customPxRatio: pixelRatio,
                }, {
                    measureUnit: mu,
                    pageWidth,
                    labelMargin: margin,
                    pageHeight,
                    pages: pageCount,
                    pageMargin
                }
            );

            setErrored(false);
        } catch (e) {
            setErrored(true);
            setTimeout(() => {
                setErrored(false);
            }, 30000);
            console.error(e);
        }

        setIsDownloading(false);
    }, [pixelRatio, setIsDownloading, setErrored, pageSize, pageCount, margin, props.variableKeyValues, pageMargin]);

    const calculateSeriesDetails = useCallback((): SeriesDetails => {
        const mu = props.editor.projectData.projectOptions.measureUnit;
        const pageS = isoSizesWithUm(mu, pageSize.name as IsoSizes);

        return props.editor.exportFeature.calculatePdfSeriesLayout({
            labelMargin: margin,
            pages: pageCount,
            pageHeight: pageS.getHeight(),
            pageWidth: pageS.getWidth(),
            measureUnit: mu,
            pageMargin
        });
    }, [pageCount, pageSize, margin, pageMargin]);

    const generateAndDownload = useCallback(async () => {
        try {
            const varMap = objectToMap(props.variableKeyValues as Object);
            setIsDownloading(true);
            // Export effettivo
            await props.editor.exportFeature.exportLabelToPdf(
                props.editor.getFilename(
                    'pdf'),
                varMap, pixelRatio,
            );

            setErrored(false);
        } catch (e) {
            setErrored(true);
            setTimeout(() => {
                setErrored(false);
            }, 30000);
            console.error(e);
        }

        setIsDownloading(false);
    }, [pixelRatio, setIsDownloading, setErrored, props.variableKeyValues]);

    /**
     Calcola il pixelratio per raggiungere la qualità scelta
     */
    const qualityResolver = (quality: NameValue): number => {
        const resolutionW = props.editor.projectData.labelWidth;
        const resolutionH = props.editor.projectData.labelHeight;

        if (quality.value == PdfQualityList.LOW.value)
            return 1;

        const MAX_RES = 20000;

        if (resolutionW * 8 < MAX_RES && resolutionH * 8 < MAX_RES) return 8;
        else return Math.floor(Math.min(MAX_RES / resolutionW, MAX_RES / resolutionH));
    }

    const handlePixelRatioChange = useCallback((item: NameValue) => {
        const r = qualityResolver(item);

        setPixelRatio(r);
    }, [setPixelRatio, qualityResolver])

    useEffect(() => {
        setPixelRatio(qualityResolver(PdfQualityList.HIGH));
        setSeriesAvailable(props.editor.exportFeature.checkSizeFits({name: IsoSizes.A4, value: IsoSizes.A4}));
    }, [props.editor, setSeriesAvailable]);

    return <div className="mle-project-config-tab">
        {
            errored && <ExportErrorsCallout className="mb-4"/>
        }
        <div className="my-1">
            Qualità
        </div>
        <div className="mb-1.5">
            <small className="bp4-text-muted">Tutte le opzioni di esportazione PDF,
                inclusa la risoluzione di eventuali contenuti bitmap, vengono al momento
                gestite automaticamente.</small>
        </div>
        <div className="mb-4">
            <MleCustomSelect name="quality" initalValue={PdfQualityList.HIGH} icon="settings"
                             items={Object.keys(PdfQualityList).map((it: string) => {
                                 const content = (PdfQualityList as any)[it];
                                 return {
                                     name: content.name,
                                     "value": content.value
                                 }
                             })} renderLabel={t => t.value} renderText={t => t["name"]}
                             onSelect={handlePixelRatioChange}/>
        </div>
        <div className="my-4">
            <Button disabled={!seriesAvailable}
                    onClick={() => setEnableSeries(!enableSeries)} icon={!enableSeries ? "caret-up" : "caret-down"}
                    minimal>Serie
                di etichette</Button>
            {!seriesAvailable && <div className="bp4-text-muted">
                <Icon icon="info-sign" intent={Intent.PRIMARY} className="mr-2"/>
                L'etichetta è troppo grande, generazione in serie non disponibile. (Massimo A4)
            </div>}
            <Collapse isOpen={enableSeries}>
                <small className="bp4-text-muted">Permette di creare un pdf con più di un'etichetta al suo interno.
                    Seleziona le dimensioni della pagina e imposta un margine, il resto del lavoro verrà svolto da
                    MyLabel Editor.</small>
                <div className="flex flex-row">
                    <div className="flex flex-col space-y-2 w-3/4 my-2">

                        <FormGroup label="Dimensioni pagina">
                            <div className="flex flex-row space-x-2">
                                <ISOSizeSelect className="my-2"
                                               intent={invalidSize ? Intent.DANGER : Intent.NONE}
                                               measureUnit={props.editor.projectData.projectOptions.measureUnit}
                                               onSizeSelect={size => {
                                                   const exportF = props.editor.exportFeature;
                                                   setPageSize(size as any);
                                                   setInvalidSize(!exportF.checkSizeFits(size as any))
                                               }}
                                               initialValue={IsoSizes.A4}/>
                                {
                                    invalidSize &&
                                    <small className="text-red-700">Dimensione della pagina troppo piccola. Deve
                                        essere
                                        di
                                        almeno <ProjectSizeLabel
                                            editor={props.editor}/>
                                    </small>
                                }
                            </div>
                        </FormGroup>
                        <FormGroup label="Margine pagina"
                                   labelInfo={`(${props.editor.projectData.projectOptions.measureUnit})`}
                                   subLabel="Spazio ai bordi della pagina">
                            <NumericInput className="w-5" fill={false} placeholder="Inserisci il margine pagina"
                                          defaultValue={5}
                                          min={0} max={20} name="pageMargin"
                                          onValueChange={value => setPageMargin(value)}/>
                        </FormGroup>
                        <div className="pt-6">
                            <FormGroup
                                label="Margine etichette"
                                labelInfo={`(${props.editor.projectData.projectOptions.measureUnit})`}
                                subLabel="Spazio tra le etichette">
                                <NumericInput className="w-5" fill={false} placeholder="Inserisci il margine etichetta"
                                              defaultValue={0}
                                              min={0} max={20} name="margin"
                                              onValueChange={value => setMargin(value)}/>

                            </FormGroup>
                            <FormGroup label="Pagine" subLabel="Numero di pagine da generare">
                                <NumericInput className="w-5" fill={false} placeholder="Inserisci il numero di pagine"
                                              defaultValue={1} min={1} max={20} name="pages"
                                              onValueChange={value => setPageCount(value)}/>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <SeriesDetailThumbinail details={calculateSeriesDetails()}/>
                    </div>
                </div>
            </Collapse>
        </div>
        <Button
            loading={isDownloading}
            icon="document"
            intent={Intent.PRIMARY}
            onClick={enableSeries ? generateAndDownloadSeries : generateAndDownload}
            disabled={errored || isDownloading || invalidSize}
        >
            {
                enableSeries ? "Scarica serie PDF" : "Scarica PDF"
            }
        </Button>
    </div>;
};
