import { Editor }             from '../../../../store/observables/Editor';
import { Button, Intent }     from '@blueprintjs/core';
import { useState }           from 'react';
import { TableEditingDialog } from './TableEditingDialog';

interface TableTabProps {
  editor: Editor;
}

export const TableTab = (props: TableTabProps) => {
  const [ editingDialogIsOpen, setEditingDialogIsOpen ] = useState(false);

  return <div>
    <Button
      className="w-full mb-3"
      small
      intent={ Intent.SUCCESS }
      onClick={ () => setEditingDialogIsOpen(true) }
    >
      Apri finestra di modifica
    </Button>
    <Button
      small
      className="w-full"
      disabled
    >
      Esporta contenuti
    </Button>
    <TableEditingDialog
      editor={ props.editor }
      isOpen={ editingDialogIsOpen }
      onClose={ () => setEditingDialogIsOpen(false) }
    />
  </div>;
};
