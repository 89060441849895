import Pamela                       from '@revodigital/pamela';
import { TRANSFORMER_NAME }         from './pamela-names';
import { ObservableShape }          from '../store/observables/ObservableShape';
import { Editor }                   from '../store/observables/Editor';
import { constructObservableShape } from '../store/observables';

export class PamelaEntitiesProvider {
  private static stage?: Pamela.Stage;
  private static layer?: Pamela.Layer;
  private static trLayer?: Pamela.Layer;
  private static transformer?: Pamela.Transformer;

  protected constructor() {}

  static getStageInstance(labelWidth: number, labelHeight: number, containerId: string) {
    if (!PamelaEntitiesProvider.stage) {
      PamelaEntitiesProvider.stage = new Pamela.Stage({
        container: containerId,
        //width: labelWidth,
        //height: labelHeight,
      });
    }
    return PamelaEntitiesProvider.stage;
  }

  static getLayerInstance() {
    if (!PamelaEntitiesProvider.layer) {
      PamelaEntitiesProvider.layer = new Pamela.Layer();
      if (PamelaEntitiesProvider.stage)
        PamelaEntitiesProvider.stage.add(PamelaEntitiesProvider.layer);

      PamelaEntitiesProvider.layer.draw();
    }
    return PamelaEntitiesProvider.layer;
  }

  static getTransformerInstance() {
    if (!PamelaEntitiesProvider.transformer) {
      PamelaEntitiesProvider.trLayer = new Pamela.Layer();
      PamelaEntitiesProvider.transformer = new Pamela.Transformer({
        rotationSnaps: [ 0, 90, 180, 270 ],
        anchorStroke: '#2B95D6',
        anchorFill: '#ffffff',
        anchorSize: 8,
        anchorCornerRadius: 2,
        borderStroke: '#2B95D6',
        borderStrokeWidth: 2.2,
        name: TRANSFORMER_NAME
      });
    }

    if (PamelaEntitiesProvider.trLayer && PamelaEntitiesProvider.stage) {
      PamelaEntitiesProvider.stage.add(PamelaEntitiesProvider.trLayer);
      PamelaEntitiesProvider.trLayer.add(PamelaEntitiesProvider.transformer);
    }
    return PamelaEntitiesProvider.transformer;
  }

  static getAll(labelWidth: number, labelHeight: number, containerId: string): {
    stage: Pamela.Stage, layer: Pamela.Layer, transformer: Pamela.Transformer
  } {
    const stage = PamelaEntitiesProvider.getStageInstance(labelWidth,
      labelHeight,
      containerId);
    const layer = PamelaEntitiesProvider.getLayerInstance();
    const transformer = PamelaEntitiesProvider.getTransformerInstance();

    return { stage, layer, transformer };
  }

  static destroyAll() {
    PamelaEntitiesProvider.stage = undefined;
    PamelaEntitiesProvider.layer = undefined;
    PamelaEntitiesProvider.trLayer = undefined;
    PamelaEntitiesProvider.transformer = undefined;
  }
}

export const pamelaLayerToObservables = (layer: Pamela.Layer, editor: Editor): ObservableShape<any>[] => {
  return layer
    .getChildren(node => node.id().includes('mle_'))
    .map((shape) => constructObservableShape(shape as Pamela.Shape, editor) as ObservableShape<any>);
};
