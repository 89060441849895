import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import React                           from 'react';

export const SupportMenu = () => {
  return (
    <Menu>
      <MenuItem
        disabled
        text="Contattaci e richiedi supporto"
      />
      <MenuDivider/>
      <MenuItem
        icon="envelope"
        target="_blank"
        href="mailto:editor@mylabel.it?subject=Ho%20bisogno%20di%20aiuto%20con%20MLEditor"
        text="editor@mylabel.it"
      />
    </Menu>
  );
};
