import Pamela                 from '@revodigital/pamela';
import { Editor }             from '../../store/observables/Editor';
import { getShapeTypeFromId } from '../utils';
import { ShapeTypes }         from '../enums';
import { ObservableCircle }   from '../../store/observables/ObservableCircle';

export const updateDimensionsOnTransform = (editor: Editor, e: Pamela.KonvaEventObject<any>) => {
  if (!editor.hasSelection() && e.target)
    editor.select(e.target as Pamela.Shape);

  // Resize using effective size only on some shapes
  const initialPosition = e.target.getPosition();
  let newWidth = e.target.width() * e.target.scaleX();
  let newHeight = e.target.height() * e.target.scaleY();

  e.target.width(newWidth);
  e.target.height(newHeight);
  e.target.scaleX(1);
  e.target.scaleY(1);
  editor.pamelaTransformer__nonObservable.width(e.target.width());
  editor.pamelaTransformer__nonObservable.height(e.target.height());
  e.target.x(initialPosition.x);
  e.target.y(initialPosition.y);

  if (getShapeTypeFromId(e.target.id()) === ShapeTypes.CIRCLE)
    editor.getSelectedShape<ObservableCircle>().setRadius(editor.pixelToCurrent(
      (e.target as Pamela.Circle).radius()));

  editor.getSelectedShape().setWidth(editor.pixelToCurrent(e.target.width()));
  editor.getSelectedShape().setHeight(editor.pixelToCurrent(e.target.height()));
  editor.getSelectedShape().setRotation(e.target.rotation());
  editor.getSelectedShape().setScaleX(e.target.scaleX());
  editor.getSelectedShape().setScaleY(e.target.scaleY());
};
