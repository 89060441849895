import Pamela                                           from '@revodigital/pamela';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  MeasureUnits
}                                                       from '../../common/enums';
import {
  StrokeProperties
}                                                       from './StrokeProperties';
import { Editor }                                       from './Editor';
import {
  Size2D
}                                                       from '@revodigital/pamela/lib/common/Size2D';

export interface ObservableSetterOptions {
  applyToPamelaShape: boolean;
}

export const defaultSetterOptions = {
  applyToPamelaShape: false,
  convertFrom: MeasureUnits.PX
} as ObservableSetterOptions;

export abstract class ObservableShape<T extends Pamela.Shape = any> {
  public readonly pamelaShape__nonObservable: T;
  public readonly editor: Editor;
  @observable
  private _width: number;
  @observable
  private _height: number;
  @observable
  private _x: number;
  @observable
  private _y: number;
  @observable
  private _rotation: number;
  @observable
  private _name: string;
  @observable
  private _visible: boolean;
  @observable
  private _fill: string;
  @observable
  private _scaleX?: number;
  @observable
  private _scaleY?: number;
  @observable
  private readonly _stroke: StrokeProperties;

  protected constructor(shape: T, editor: Editor) {
    this.pamelaShape__nonObservable = shape;
    this._width = editor.pixelToCurrent(shape.width());
    this._height = editor.pixelToCurrent(shape.height());
    this._rotation = Math.round(shape.rotation());
    this._x = editor.pixelToCurrent(shape.x());
    this._y = editor.pixelToCurrent(shape.y());
    this._name = shape.name();
    this._visible = shape.visible();
    this._fill = shape.fill();
    this.editor = editor;
    this._scaleX = shape.scaleX();
    this._scaleY = shape.scaleY();
    this._stroke = new StrokeProperties(this);

    makeObservable(this, undefined, { autoBind: true });
  }

  @computed
  public get scaleX(): number | undefined {
    return parseFloat((this._scaleX as number).toPrecision(2));
  }

  @computed
  public get scaleY(): number | undefined {
    return parseFloat((this._scaleY as number).toPrecision(2));
  }

  public get className() {
    return this.pamelaShape__nonObservable.className;
  }

  public is(className: string): boolean {
    return this.pamelaShape__nonObservable.className === className;
  }

  public isTable(): boolean {
    return this.is(Pamela.Table.prototype.className);
  }

  public isRichText(): boolean {
    return this.is(Pamela.RichText.prototype.className);
  }

  public get stroke(): StrokeProperties {
    return this._stroke;
  }

  @computed
  public get fill(): string {
    return this._fill;
  }

  @computed
  public get visible(): boolean {
    return this._visible;
  }

  @computed
  public get name(): string {
    return this._name;
  }

  @computed
  public get width(): number {
    return Math.floor(this._width);
  }

  @computed
  public get height(): number {
    return Math.floor(this._height);
  }

  @computed
  public get x(): number {
    return Math.floor(this._x);
  }

  @computed
  public get y(): number {
    return Math.floor(this._y);
  }

  @computed
  public get rotation(): number {
    return Math.floor(this._rotation);
  }

  @action
  public setScaleX(value: number, options: ObservableSetterOptions = defaultSetterOptions) {
    this._scaleX = value;
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.scaleX(value);
    this.editor.history.takeSnapshot();
  }

  @action
  public setScaleY(value: number, options: ObservableSetterOptions = defaultSetterOptions) {
    this._scaleY = value;
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.scaleY(value);
    this.editor.history.takeSnapshot();
  }

  @action
  public setSize(size: Size2D, options: ObservableSetterOptions = defaultSetterOptions) {
    this.setWidth(size.getWidth(), options);
    this.setHeight(size.getHeight(), options);
  }

  @action
  public setFill(value: string, options: ObservableSetterOptions = defaultSetterOptions) {
    this._fill = value;
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.fill(value);
    this.editor.history.takeSnapshot();
  }

  @action
  public setWidth(value: number, options = defaultSetterOptions) {
    this._width = Math.round(value);
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.width(this.editor.currentToPixel(value));
  }

  @action
  public setHeight(value: number, options = defaultSetterOptions) {
    this._height = Math.round(value);
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.height(this.editor.currentToPixel(value));
  }

  @action
  public setX(value: number, options = defaultSetterOptions) {
    this._x = Math.round(value);

    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.x(this.editor.currentToPixel(value));
  }

  @action
  public setXPixels(value: number, options = defaultSetterOptions) {
    this._x = this.editor.pixelToCurrent(value);

    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.x(value);
  }

  @action
  public setYPixels(value: number, options = defaultSetterOptions) {
    this._y = this.editor.pixelToCurrent(value);

    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.y(value);
  }

  @action
  public setY(value: number, options = defaultSetterOptions) {
    this._y = Math.round(value);
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.y(this.editor.currentToPixel(value));
  }

  @action
  public setRotation(value: number, options = defaultSetterOptions) {
    this._rotation = Math.round(value);
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.rotation(value);

    this.editor.history.takeSnapshot();
  }

  @action
  public setName(value: string, options: Partial<ObservableSetterOptions> = defaultSetterOptions) {
    this._name = value;
    if (options.applyToPamelaShape)
      this.pamelaShape__nonObservable.name(value);

    this.editor.history.takeSnapshot();
  }

  @action
  public setVisible(value: boolean, options: Partial<ObservableSetterOptions> = defaultSetterOptions) {
    this._visible = value;
    if (options.applyToPamelaShape)
      if (!value)
        this.pamelaShape__nonObservable.hide();
      else this.pamelaShape__nonObservable.show();

    this.editor.history.takeSnapshot();
  }
}