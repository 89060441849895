import React, {useCallback, useState} from 'react';
import {Menu, MenuDivider, MenuItem} from '@blueprintjs/core';
import {shapeHumanReadableNameAssociation, shapeIconAssociation} from '../../../common/utils';
import {ShapeTypes} from '../../../common/enums';
import {Tooltip2} from '@blueprintjs/popover2';
import {Editor} from '../../../store/observables/Editor';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {
    MyLabelPrecompiledField
} from "../../../store/features/shape/precompiled/factory/mylabel/MyLabelPrecompiledFieldFactory";
import {DynamicDataRef} from "@revodigital/mpes";
import {DataRefNameFactory} from "@revodigital/mpes/lib/components/data/DataRefNameFactory";
import {PrecompiledFilterableList} from "../../common/list/FilterableList";
import {
    MyLabelPrecompiledTable
} from "../../../store/features/shape/precompiled/factory/mylabel/MyLabelPrecompiledTableFactory";
import Pamela from "@revodigital/pamela";

export interface NewShapeMenuProps {
    editor: Editor;
    onAddImage: () => any;
}

export const NewShapeMenu_ = (props: NewShapeMenuProps) => {
    const authData = useSelector((state: RootState) => state.authState);
    const mlPrecompFeature = props.editor.myLabelPrecompiledFeature;
    const shapesFeature = props.editor.shapesFeature;
    const dataOriginFeature = props.editor.dataOriginFeature;
    const [searchTerm, setSearchTerm] = useState('');

    const handleAddPrecompiled = useCallback(async (data: MyLabelPrecompiledField) => {
        const factory = mlPrecompFeature.fieldFactory;

        const shape = factory.produce(data)
        if (shape)
            shapesFeature.addShape(shape, data.shapeType);


        const projDT = await dataOriginFeature.getProjectDataOrigin(
            authData.user.company?.id as number);
        const nameFactory = new DataRefNameFactory();

        if (projDT) {
            // Check data origin
            dataOriginFeature.addDataRef(new DynamicDataRef(projDT.id.toString(), nameFactory.extractName(data.fieldName)))
        }

    }, [mlPrecompFeature, shapesFeature, dataOriginFeature]);

    const handleAddPrecompTable = useCallback(async (data: MyLabelPrecompiledTable) => {
        shapesFeature.addShape(mlPrecompFeature.tableFactory.produce(data) as Pamela.Shape, ShapeTypes.TABLE);

        // Get all dataorigins and connect them
        const allDTS: string[] = [];

        data.data.forEach(row => {
            row.forEach(column => {
                if (column.includes("{{") && column.includes("}}")) allDTS.push(column.substring(column.indexOf("{{"), column.indexOf("}}") + 2))
            })
        })

        await dataOriginFeature.connectFields(allDTS, authData.user.company?.id as number);
    }, [mlPrecompFeature, shapesFeature, dataOriginFeature, authData])

    return (
        <Menu>
            <MenuDivider title={<span className="bp4-text-muted mle-info-heading">
          Generali
        </span>}/>
            <MenuItem
                icon="new-object"
                text="Forma"
            >
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.RECT]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.RECT]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.RECT)}
                />
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.CIRCLE]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.CIRCLE]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.CIRCLE)}
                />
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.ELLIPSE]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.ELLIPSE]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.ELLIPSE)}
                />
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.WEDGE]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.WEDGE]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.WEDGE)}
                />
            </MenuItem>
            <MenuItem
                icon="paragraph"
                text="Testo"
            >
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.RICH_TEXT]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.RICH_TEXT]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.RICH_TEXT)}
                />
                <MenuItem
                    icon={shapeIconAssociation[ShapeTypes.TEXT]}
                    text={shapeHumanReadableNameAssociation[ShapeTypes.TEXT]}
                    onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.TEXT)}
                />
            </MenuItem>
            <MenuItem
                className="w-full"
                icon={shapeIconAssociation[ShapeTypes.IMAGE]}
                text={shapeHumanReadableNameAssociation[ShapeTypes.IMAGE]}
                onClick={props.onAddImage}
            />
            <div>
                <Tooltip2 content="In arrivo nei prossimi rilasci">
                    <MenuItem
                        disabled
                        icon={shapeIconAssociation[ShapeTypes.LINE]}
                        text="Linea"
                    />
                </Tooltip2>
            </div>
            <div>
                <Tooltip2 content="In arrivo nei prossimi rilasci">
                    <MenuItem
                        disabled
                        icon={shapeIconAssociation[ShapeTypes.POLYGON]}
                        text={shapeHumanReadableNameAssociation[ShapeTypes.POLYGON]}
                        onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.POLYGON)}
                    />
                </Tooltip2>
            </div>
            <MenuDivider
                title={<span className="bp4-text-muted mle-info-heading">
          Dati
        </span>}/>
            <MenuItem
                icon={shapeIconAssociation[ShapeTypes.TABLE]}
                text={shapeHumanReadableNameAssociation[ShapeTypes.TABLE]}
                onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.TABLE)}
            />
            <MenuItem text="Precompilati MyLabel" icon="star"
                      disabled={!props.editor.mylabelAuthFeature.isConnected || !props.editor.recipeSwitcher.hasActiveRevision || !props.editor.recipeSwitcher.hasActiveCompany}>
                <PrecompiledFilterableList data={mlPrecompFeature.fieldIndex} onClick={handleAddPrecompiled}/>
            </MenuItem>
            <MenuItem text="Tabelle MyLabel" icon="panel-table"
                      disabled={!props.editor.mylabelAuthFeature.isConnected || !props.editor.recipeSwitcher.hasActiveRevision || !props.editor.recipeSwitcher.hasActiveCompany}>
                <PrecompiledFilterableList data={mlPrecompFeature.tableIndex} onClick={handleAddPrecompTable}/>
            </MenuItem>
            <MenuItem
                icon={shapeIconAssociation[ShapeTypes.BARCODE]}
                text={shapeHumanReadableNameAssociation[ShapeTypes.BARCODE]}
                onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.BARCODE)}
            />
            <MenuItem
                icon={shapeIconAssociation[ShapeTypes.EXPORT_VARIABLE]}
                text={shapeHumanReadableNameAssociation[ShapeTypes.EXPORT_VARIABLE]}
                onClick={() => props.editor.shapesFeature.createShape(ShapeTypes.EXPORT_VARIABLE)}/>
            <div>
                <Tooltip2 content="In arrivo nei prossimi rilasci">
                    <MenuItem
                        disabled
                        icon="data-connection"
                        text="Origine dati"
                    />
                </Tooltip2>
            </div>
        </Menu>
    );
};
