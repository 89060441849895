import { Editor }                                        from '../../../../store/observables/Editor';
import classNames                                        from 'classnames';
import {
  currentThemeIsDark
}                                                        from '../../../../common/utils';
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Divider,
  InputGroup,
  Intent,
  NumericInput,
  Switch,
  Tag
}                                                        from '@blueprintjs/core';
import {
  CellRenderer,
  Column,
  ColumnHeaderCell,
  EditableCell2,
  RowHeaderCell,
  Table2
}                                                        from '@blueprintjs/table';
import { TableDetails }                                  from './TableDetails';
import * as React                                        from 'react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  CellConfig
}                                                        from '@revodigital/pamela/lib/shapes/cell';
import {
  ObservableTable
}                                                        from '../../../../store/observables/ObservableTable';
import {
  Matrix2D,
  MatrixIndex,
  matrixRepeat
}                                                        from '@revodigital/pamela/lib/common/Matrix2D';
import {
  Popover2
}                                                        from '@blueprintjs/popover2';
import {
  HorizontalAlignment,
  VerticalAlignment
}                                                        from '@revodigital/pamela/lib/configuration/Alignment';
import {
  IFocusedCellCoordinates
}                                                        from '@blueprintjs/table/lib/esnext/common/cellTypes';
import {
  Verse
}                                                        from '@revodigital/pamela/lib/shapes/Verse';
import {
  LinkDataOriginField
}                                                        from '../dataorigins/LinkDataOriginField';

interface TableEditingDialogProps {
  editor: Editor;
  isOpen: boolean;
  onClose: (...args: any) => any;
}

enum Modifiers {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underlined',
  TEXT_HORIZONTAL_ALIGN = 'textAlign',
  TEXT_VERTICAL_ALIGN = 'verticalAlign',
  FONT_SIZE = 'fontSize'
}

export interface CellIndex {
  rowIndex: number;
  columnIndex: number;
}

export const TableEditingDialog = (props: TableEditingDialogProps) => {
  const pamelaTable = props.editor.getSelectedShape<ObservableTable>().pamelaShape__nonObservable;

  const [selectionIndex, setSelectionIndex] = useState<CellIndex | undefined>();
  // TODO: Includere la gestione dei contenuti nella matrice di configurazione
  const [editingContentMatrix, setEditingContentMatrix] = useState<Matrix2D<string>>(
    matrixRepeat('', pamelaTable.countColumns(), pamelaTable.countRows())
  );
  const [editingConfigMatrix, setEditingConfigMatrix] = useState<Matrix2D<CellConfig>>(
    matrixRepeat({}, pamelaTable.countColumns(), pamelaTable.countRows())
  );
  const [editingColumnsWidth, setEditingColumnsWidth] = useState<number[]>([]);
  const [editingRowsHeight, setEditingRowsHeight] = useState<number[]>([]);

  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const [addingDataOrigin, setAddingDataOrigin] = useState(false);

  useEffect(() => {
    let tempContentMatrix = matrixRepeat('',
      pamelaTable.countColumns(),
      pamelaTable.countRows());
    let tempConfigMatrix = matrixRepeat({},
      pamelaTable.countColumns(),
      pamelaTable.countRows());
    let tempEditingColumns: number[] = [];
    let tempEditingRows: number[] = [];
    let columnIndex = 0;

    pamelaTable.getCellsMatrix().forEachColumn((col) => {
      tempEditingColumns[columnIndex] = col[0].autoWidth ? 0 : col[0].width as number;
      col.forEach((cell, rowIndex) => {
        tempEditingRows[rowIndex] = cell.autoWidth ? 0 : cell.width as number;
        tempContentMatrix.data[rowIndex][columnIndex] = cell.content as string;
        tempConfigMatrix.data[rowIndex][columnIndex] = cell;
      });
      columnIndex++;
    });

    setEditingContentMatrix(tempContentMatrix);
    setEditingConfigMatrix(tempConfigMatrix);
    setEditingColumnsWidth(tempEditingColumns);
    setEditingRowsHeight(tempEditingRows);
    setSelectionIndex({
      columnIndex: 0,
      rowIndex: 0
    });
  }, [props.editor]);

  const handleCellFocus = (cellCoordinates: IFocusedCellCoordinates) => {
    setSelectedRowIndex(-1);
    setSelectedColumnIndex(-1);
    setSelectionIndex({
      columnIndex: cellCoordinates.col,
      rowIndex: cellCoordinates.row
    });
  };

  const rowIsSelected = () => selectedRowIndex >= 0;

  const columnIsSelected = () => selectedColumnIndex >= 0;

  const currentSizeIsAuto = () => {
    if (rowIsSelected() && !editingRowsHeight[selectedRowIndex]) return true;
    return columnIsSelected() && !editingColumnsWidth[selectedColumnIndex];
  };

  const currentSize = () => {
    if (rowIsSelected()) return editingRowsHeight[selectedRowIndex] as number;
    if (columnIsSelected()) return editingColumnsWidth[selectedColumnIndex] as number;
  };

  const updateMatrixAttributes = (rowsHeight: number[], columnsWidth: number[]) => {
    const tempEditingConfigMatrix = editingConfigMatrix.slice();
    let rowIndex = 0;

    tempEditingConfigMatrix.forEachRow((row) => {
      row.forEach((cell, columnIndex) => {
        cell.autoWidth = !columnsWidth[columnIndex];
        cell.autoHeight = !rowsHeight[rowIndex];
        cell.height = rowsHeight[rowIndex];
        cell.width = columnsWidth[columnIndex];
      });
      rowIndex++;
    });
    setEditingConfigMatrix(tempEditingConfigMatrix);
  };

  const removeCellStyleFromConfig = (cell: CellConfig) => ({
    ...cell,
    bold: false,
    italic: false,
    underlined: false,
    fontSize: 20,
    textAlign: HorizontalAlignment.Left,
    verticalAlign: VerticalAlignment.Center
  });

  const addRow = useCallback((index: number, position?: Verse) => {
      const tempEditingConfigMatrix = editingConfigMatrix.slice();
      const tempEditingContentMatrix = editingContentMatrix.slice();

      tempEditingConfigMatrix.insertRow((editingConfigMatrix.getRow(
          pamelaTable.getRowsCount() - 1) as CellConfig[]).map(
          removeCellStyleFromConfig),
        index >= 0 ? index : 0,
        position ?? Verse.After);

      tempEditingContentMatrix.insertRow(new Array(pamelaTable.getColumnsCount())
          .fill(''), index >= 0 ? index : 0,
        position ?? Verse.After);
      setEditingConfigMatrix(tempEditingConfigMatrix);
      setEditingContentMatrix(tempEditingContentMatrix);
    },
    [setEditingConfigMatrix, setEditingContentMatrix, pamelaTable, editingConfigMatrix, editingContentMatrix]);

  const addColumn = (index: number, position?: Verse) => {
    const tempEditingConfigMatrix = editingConfigMatrix.slice();
    const tempEditingContentMatrix = editingContentMatrix.slice();

    tempEditingConfigMatrix.insertColumn((editingConfigMatrix.getColumn(
        pamelaTable.getColumnsCount() - 1) as CellConfig[]).map(
        removeCellStyleFromConfig),
      index >= 0 ? index : 0,
      position ?? Verse.After);

    tempEditingContentMatrix.insertColumn(new Array(pamelaTable.getRowsCount())
      .fill(''), index >= 0 ? index : 0, position ?? Verse.After);
    setEditingConfigMatrix(tempEditingConfigMatrix);
    setEditingContentMatrix(tempEditingContentMatrix);
  };

  const removeRow = (index: number) => {
    setSelectionIndex(undefined);
    const tempEditingConfigMatrix = editingConfigMatrix.slice();
    const tempEditingContentMatrix = editingContentMatrix.slice();
    tempEditingConfigMatrix.removeRow(index);
    tempEditingContentMatrix.removeRow(index);
    setEditingConfigMatrix(tempEditingConfigMatrix);
    setEditingContentMatrix(tempEditingContentMatrix);
  };

  const removeColumn = (index: number) => {
    setSelectionIndex(undefined);
    const tempEditingConfigMatrix = editingConfigMatrix.slice();
    const tempEditingContentMatrix = editingContentMatrix.slice();
    tempEditingConfigMatrix.removeColumn(index);
    tempEditingContentMatrix.removeColumn(index);
    setEditingConfigMatrix(tempEditingConfigMatrix);
    setEditingContentMatrix(tempEditingContentMatrix);
  };

  const toggleCurrentSizeMode = () => {
    const tempEditingColumnsWidth = editingColumnsWidth.slice();
    const tempEditingRowsHeight = editingRowsHeight.slice();

    if (rowIsSelected())
      tempEditingRowsHeight[selectedRowIndex] = tempEditingRowsHeight[selectedRowIndex] ? 0 : 10;
    if (columnIsSelected())
      tempEditingColumnsWidth[selectedColumnIndex] = tempEditingColumnsWidth[selectedColumnIndex] ? 0 : 10;

    setEditingColumnsWidth(tempEditingColumnsWidth);
    setEditingRowsHeight(tempEditingRowsHeight);
    updateMatrixAttributes(tempEditingRowsHeight, tempEditingColumnsWidth);
  };

  const handleCurrentSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempEditingRowsHeight = editingRowsHeight.slice();
    const tempEditingColumnsWidth = editingColumnsWidth.slice();

    if (rowIsSelected())
      tempEditingRowsHeight[selectedRowIndex] = parseInt(e.target.value);
    if (columnIsSelected())
      tempEditingColumnsWidth[selectedColumnIndex] = parseInt(e.target.value);

    setEditingRowsHeight(tempEditingRowsHeight);
    setEditingColumnsWidth(tempEditingColumnsWidth);
    updateMatrixAttributes(tempEditingRowsHeight, tempEditingColumnsWidth);
  };

  const rowAtIndexIsAuto = (rowIndex: number) => {
    return !editingRowsHeight[rowIndex];
  };

  const columnAtIndexIsAuto = (columnIndex: number) => {
    return !editingColumnsWidth[columnIndex];
  };

  const renderCell: CellRenderer = useCallback((rowIndex, columnIndex) => {
    return <EditableCell2
      key={ `${ rowIndex }:${ columnIndex }` }
      onConfirm={ (value) => insertUpdatedContent(value,
        { rowIndex, columnIndex }) }
      value={ editingContentMatrix.data[rowIndex][columnIndex] }
    />;
  }, [editingConfigMatrix, editingContentMatrix]);

  const composeColumnName = (columnIndex: number) => `${ (columnIndex + 10).toString(
    36).toUpperCase() } (${ columnAtIndexIsAuto(columnIndex) ? 'auto' : editingColumnsWidth[columnIndex] })`;

  const getColumns = useCallback(() => {
    const columns: JSX.Element[] = [];

    for (let loopColumnIndex = 0; loopColumnIndex < editingConfigMatrix.getColumnsCount(); loopColumnIndex++)
      columns.push(<Column
        columnHeaderCellRenderer={ columnIndex =>
          <span onClick={ () => setSelectedColumnIndex(columnIndex) }>
          <ColumnHeaderCell
            index={ columnIndex }
            name={ composeColumnName(columnIndex) }
          />
          </span>
        }
        key={ loopColumnIndex }
        cellRenderer={ renderCell }
      />);

    return columns;
  }, [editingContentMatrix, editingConfigMatrix]);

  const insertUpdatedContent = (newContent: string, index: CellIndex) => {
    const tempData = editingContentMatrix?.data.slice() as string[][];
    tempData[index.rowIndex][index.columnIndex] = newContent;
    setEditingContentMatrix(new Matrix2D(tempData));
  };

  const apply = () => {
    pamelaTable
      .toBuilder()
      .setCells(editingConfigMatrix)
      .adaptHSpace()
      .adaptVSpace()
      .populateContent(editingContentMatrix, true)
      .buildTo(pamelaTable);
    pamelaTable.draw();
    props.editor.pamelaLayer__nonObservable.draw();
    props.onClose();
  };

  const getIntentByModifierStatus = <T extends any>(modifier: Modifiers, compareTo?: T) => {
    if (!selectionIndex) return Intent.NONE;
    const cellConfig = (editingConfigMatrix.getCell(new MatrixIndex(
      selectionIndex.columnIndex,
      selectionIndex.rowIndex
    )) as CellConfig);
    if (((compareTo !== undefined) && (cellConfig[modifier] === compareTo)) || (compareTo === undefined && !!cellConfig[modifier])) return Intent.PRIMARY;
    return Intent.NONE;
  };

  const toggleModifier = (modifier: Modifiers) => {
    const tempData = editingConfigMatrix.data.slice();
    const previousValue = (tempData[selectionIndex?.rowIndex as number][selectionIndex?.columnIndex as number] as CellConfig)[modifier];
    ((tempData[selectionIndex?.rowIndex as number][selectionIndex?.columnIndex as number] as CellConfig)[modifier] as any) = !previousValue;
    setEditingConfigMatrix(new Matrix2D(tempData));
  };

  const setModifier = <T extends any>(modifier: Modifiers, value: T) => {
    const tempData = editingConfigMatrix.data.slice();
    ((tempData[selectionIndex?.rowIndex as number][selectionIndex?.columnIndex as number] as CellConfig)[modifier] as any) = value;
    setEditingConfigMatrix(new Matrix2D(tempData));
  };

  const styleControls = (
    <>
      <ButtonGroup>
        <Button
          icon="bold"
          small
          minimal
          intent={ getIntentByModifierStatus(Modifiers.BOLD) }
          onClick={ () => toggleModifier(Modifiers.BOLD) }
        />
        <Button
          icon="italic"
          small
          minimal
          intent={ getIntentByModifierStatus(Modifiers.ITALIC) }
          onClick={ () => toggleModifier(Modifiers.ITALIC) }
        />
        <Button
          icon="underline"
          small
          minimal
          intent={ getIntentByModifierStatus(Modifiers.UNDERLINE) }
          onClick={ () => toggleModifier(Modifiers.UNDERLINE) }
        />
      </ButtonGroup>
    </>
  );

  const ColumnControls = () => {
    return (
      (
        <>
          <ButtonGroup>
            <Button
              icon="add-column-left"
              intent={ Intent.SUCCESS }
              small
              minimal
              onClick={ () => addColumn(selectedColumnIndex, Verse.Before) }
            />
            <Button
              icon="add-column-right"
              intent={ Intent.SUCCESS }
              small
              minimal
              onClick={ () => addColumn(selectedColumnIndex, Verse.After) }
            />
            <Button
              icon="remove-column"
              intent={ Intent.DANGER }
              small
              minimal
              onClick={ () => removeColumn(selectedColumnIndex) }
            />
          </ButtonGroup>
        </>
      ));
  };

  const RowControls = () => {
    return (
      <>
        <ButtonGroup>
          <Button
            icon="add-row-top"
            intent={ Intent.SUCCESS }
            small
            minimal
            onClick={ () => addRow(selectedRowIndex, Verse.Before) }
          />
          <Button
            icon="add-row-bottom"
            intent={ Intent.SUCCESS }
            small
            minimal
            onClick={ () => addRow(selectedRowIndex, Verse.After) }
          />
          <Button
            icon="remove-row-bottom"
            intent={ Intent.DANGER }
            small
            minimal
            onClick={ () => removeRow(selectedRowIndex) }
          />
        </ButtonGroup>
      </>
    );
  };

  const horizontalAlignmentControls = (
    <>
      <ButtonGroup>
        <Button
          icon="align-left"
          small
          minimal
          intent={ getIntentByModifierStatus<HorizontalAlignment>(
            Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Left
          ) }
          onClick={ () => setModifier<HorizontalAlignment>(Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Left) }
        />
        <Button
          icon="align-center"
          small
          minimal
          intent={ getIntentByModifierStatus<HorizontalAlignment>(
            Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Center
          ) }
          onClick={ () => setModifier<HorizontalAlignment>(Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Center) }
        />
        <Button
          icon="align-right"
          small
          minimal
          intent={ getIntentByModifierStatus<HorizontalAlignment>(
            Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Right
          ) }
          onClick={ () => setModifier<HorizontalAlignment>(Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Right) }
        />
        <Button
          icon="align-justify"
          small
          minimal
          intent={ getIntentByModifierStatus<HorizontalAlignment>(
            Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Justify
          ) }
          onClick={ () => setModifier<HorizontalAlignment>(Modifiers.TEXT_HORIZONTAL_ALIGN,
            HorizontalAlignment.Justify) }
        />
      </ButtonGroup>
    </>
  );

  const verticalAlignmentControls = (
    <>
      <ButtonGroup>
        <Button
          icon="alignment-top"
          small
          minimal
          intent={ getIntentByModifierStatus<VerticalAlignment>(
            Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Top
          ) }
          onClick={ () => setModifier<VerticalAlignment>(Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Top) }
        />
        <Button
          icon="alignment-horizontal-center"
          small
          minimal
          intent={ getIntentByModifierStatus<VerticalAlignment>(
            Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Center
          ) }
          onClick={ () => setModifier<VerticalAlignment>(Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Center) }
        />
        <Button
          icon="alignment-bottom"
          small
          minimal
          intent={ getIntentByModifierStatus<VerticalAlignment>(
            Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Bottom
          ) }
          onClick={ () => setModifier<VerticalAlignment>(Modifiers.TEXT_VERTICAL_ALIGN,
            VerticalAlignment.Bottom) }
        />
      </ButtonGroup>
    </>
  );

  const sizeControls = (
    <div className="flex flex-row align-middle">
      <div>
        <Tag
          minimal
          className="mr-2"
          intent={ Intent.PRIMARY }>Strumenti { rowIsSelected() ? 'riga' : 'colonna' }</Tag>
      </div>
      <div
        className="bp4-text-muted mr-1 text-xs mt-1">{ rowIsSelected() ? 'Altezza %' : 'Larghezza %' }</div>
      <InputGroup
        disabled={ currentSizeIsAuto() }
        small
        value={ currentSize()?.toString() }
        leftIcon={ rowIsSelected() ? 'arrows-vertical' : 'arrows-horizontal' }
        rightElement={ <Switch
          className="mt-1"
          onChange={ toggleCurrentSizeMode }
          checked={ currentSizeIsAuto() }
          innerLabelChecked="auto"
          innerLabel="man."
        /> }
        onChange={ handleCurrentSizeChange }
      />
    </div>
  );

  const topBar = (
    <div className="flex flex-row">
      <div className="p-1">
        { styleControls }
      </div>
      <Divider/>
      <div className="p-1">
        { horizontalAlignmentControls }
      </div>
      <Divider/>
      <div className="p-1">
        { verticalAlignmentControls }
      </div>

      <NumericInput
        size={ 5 }
        disabled={ !selectionIndex }
        className="ml-4"
        leftIcon="font"
        value={ selectionIndex ? editingConfigMatrix.data[selectionIndex.rowIndex][selectionIndex.columnIndex].fontSize : '' }
        onValueChange={ (newFontSize) => setModifier<number>(
          Modifiers.FONT_SIZE,
          newFontSize) }
      />
    </div>
  );

  return <Dialog
    canOutsideClickClose={ false }
    canEscapeKeyClose={ false }
    usePortal
    className={ classNames({ 'bp4-dark': currentThemeIsDark() }) }
    style={ { width: '70rem' } }
    isOpen={ props.isOpen }
    title="Modifica Tabella"
    onClose={ props.onClose }
  >
    <div className={ Classes.DIALOG_BODY }>
      <div className="flex flex-row justify-between">
        <div className="w-full mx-3">
          <div className="bp4-text-muted mle-info-heading">
            Stile e Contenuti
          </div>
          <div
            className={ classNames('w-full p-2 rounded-sm', {
              'bg-bpdisabled-dark': currentThemeIsDark(),
              'bg-bpdisabled-light': !currentThemeIsDark()
            }) }>
            <div
              className="w-full rounded-sm flex flex-col p-1 space-y-2 mb-2">
              {
                selectedColumnIndex < 0 && selectedRowIndex < 0 &&
                <>
                  <div className="py-1 pr-1">
                    <InputGroup
                      className="w-72"
                      disabled={ !selectionIndex }
                      small
                      rightElement={ <Button icon="database"
                                             minimal
                                             onClick={ () => setAddingDataOrigin(
                                               true) }/> }
                      leftIcon="new-text-box"
                      value={ selectionIndex ? editingContentMatrix.getCell(new MatrixIndex(
                        selectionIndex.columnIndex,
                        selectionIndex.rowIndex
                      )) : '' }
                      onChange={ e => insertUpdatedContent(e.target.value,
                        {
                          rowIndex: selectionIndex?.rowIndex as number,
                          columnIndex: selectionIndex?.columnIndex as number
                        }) }
                    />
                  </div>
                </>
              }
              {
                rowIsSelected() || columnIsSelected()
                ?
                <>
                  <div className="p-1">
                    { sizeControls }
                  </div>
                  <Divider/>
                  {
                    columnIsSelected() ?
                    <div className="p-1">
                      <ColumnControls/>
                    </div>
                                       :
                    <div className="p-1">
                      <RowControls/>
                    </div>
                  }
                </>
                :
                <>
                  {
                    topBar
                  }
                </>
              }
            </div>
            <Table2
              className="outline-0 focus:outline-0"
              enableFocusedCell
              onFocusedCell={ handleCellFocus }
              numRows={ editingConfigMatrix.getRowsCount() }
              rowHeaderCellRenderer={ rowIndex =>
                <span onClick={ () => setSelectedRowIndex(rowIndex) }>
                <RowHeaderCell
                  style={ { width: '1rem' } }
                  index={ rowIndex }
                  name={ (rowIndex + 1).toString() }
                />
                </span>
              }
            >
              { getColumns() }
            </Table2>
          </div>
        </div>
        <div>
          {
            addingDataOrigin ? <LinkDataOriginField editor={ props.editor }
                                                    closeOriginAdd={ () => setAddingDataOrigin(
                                                      false) }
                                                    updateCellContent={ (ct) => insertUpdatedContent(
                                                      ct,
                                                      selectionIndex || {
                                                        rowIndex: 0,
                                                        columnIndex: 0
                                                      }) }/> :
            <TableDetails editor={ props.editor }/>
          }
        </div>
      </div>
      <Divider/>
      <div className="flex flex-row justify-end gap-x-3 mt-3 -mb-6">
        <Popover2 content={ <div className="p-2 text-xs bp4-text-muted">
          Sei sicuro? Le modifiche andranno perse
          <div className="mt-1">
            <Button small onClick={ props.onClose }>Sì, annulla
              modifiche</Button>
          </div>
        </div> }>
          <Button small>Annulla</Button>
        </Popover2>
        <Button
          small
          intent={ Intent.PRIMARY }
          onClick={ apply }
        >Applica</Button>
      </div>
    </div>
  </Dialog>;
};
