import { CombosNames, MleCombos } from './mle-combos';
import { getOS }                  from './utils';
import { OSs }                    from './enums';
import { IHotkeyProps }           from '@blueprintjs/core';

interface OnKeyDownActions {
  onSave: (...args: any) => any;
  onDelete: (...args: any) => any;
  onUndo: (...args: any) => any;
  onCopy: (...args: any) => any;
  onPaste: (...args: any) => any;
  onDuplicate: (...args: any) => any;
  onPasteHere: (...args: any) => any;
}

export const getHotkeys = (onKeyDownActions: OnKeyDownActions) => ([
  {
    combo: MleCombos[CombosNames.SAVE][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.SAVE,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onSave
  },
  {
    combo: MleCombos[CombosNames.DELETE][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.DELETE,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onDelete
  },
  {
    combo: MleCombos[CombosNames.COPY][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.COPY,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onCopy
  },
  {
    combo: MleCombos[CombosNames.PASTE][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.PASTE,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onPaste
  },
  {
    combo: MleCombos[CombosNames.PASTE_HERE][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.PASTE_HERE,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onPasteHere
  },
  {
    combo: MleCombos[CombosNames.DUPLICATE][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.DUPLICATE,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onDuplicate
  },
  {
    combo: MleCombos[CombosNames.UNDO][getOS() ?? OSs.WINDOWS],
    global: true,
    label: CombosNames.UNDO,
    preventDefault: true,
    stopPropagation: true,
    onKeyDown: onKeyDownActions.onUndo
  }
] as IHotkeyProps[]);
