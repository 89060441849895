import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

export interface UserMenuProps {
  email: string;
}

export const UserMenu = (props: UserMenuProps) => {
  return (
    <Menu>
      <MenuItem disabled icon="envelope" text={ props.email }/>
      <MenuDivider/>
      <MenuItem disabled icon="user" text="Gestisci account"/>
      <MenuItem disabled icon="share" text="Condividi MLEditor"/>
    </Menu>
  );
};
