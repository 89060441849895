import { updatePositionOnDrag } from './position.listeners';
import {
  updateDimensionsOnTransform
}                               from './transform.listeners';
import {
  checkSelectionAvailability,
  updateSelectionOnMouseDown
}                               from './selection.listeners';
import {
  applyGuidesOnDrag,
  removeGuides
}                               from './guides.listeners';
import {
  Editor
}                               from '../../store/observables/Editor';

export const initListeners = (editor: Editor) => {
  editor.pamelaStage__nonObservable.on('mousedown',
    (e) => updateSelectionOnMouseDown(editor,
      editor.pamelaStage__nonObservable,
      editor.pamelaTransformer__nonObservable,
      e));
  editor.pamelaStage__nonObservable.on('mousedown click tap',
    (e) => {
      checkSelectionAvailability(editor.pamelaStage__nonObservable,
        editor.pamelaTransformer__nonObservable,
        e);
    });
  editor.pamelaStage__nonObservable.on('dragstart', (e) => {
    if (e.evt?.altKey) {
      e.target.stopDrag();
      editor.pamelaStage__nonObservable.startDrag();
      return;
    }

    if (e.target !== editor.pamelaStage__nonObservable)
      editor.history.takeSnapshot();
  });

  editor.pamelaLayer__nonObservable.on('dragmove', (e) => {
    updatePositionOnDrag(editor, e);
    applyGuidesOnDrag(editor.pamelaLayer__nonObservable,
      editor.pamelaStage__nonObservable,
      e);
  });
  editor.pamelaLayer__nonObservable.on('dragend',
    () => {
      removeGuides(editor.pamelaLayer__nonObservable);
      editor.history.takeSnapshot();
    });

  editor.pamelaTransformer__nonObservable.on(
    'transform',
    (e) => updateDimensionsOnTransform(editor, e));
};
