import Pamela                               from '@revodigital/pamela';
import { GUIDELINE_NAME, TRANSFORMER_NAME } from '../pamela-names';

const GUIDELINE_OFFSET = 8;
const START = 55
const CENTER = 22;
const END = 12;

export const getGuides = (lineGuideStops: any, itemBounds: any) => {
  const resultV: any[] = [];
  const resultH: any[] = [];

  lineGuideStops.vertical.forEach((lineGuide: any) => {
    itemBounds.vertical.forEach((itemBound: any) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide: any) => {
    itemBounds.horizontal.forEach((itemBound: any) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  const guides = [];

  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: 'V',
      snap: minV.snap,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: 'H',
      snap: minH.snap,
    });
  }
  return guides;
};

export const getObjectSnappingEdges = (node: Pamela.Node) => {
  const box = node.getClientRect();
  const absPos = node.absolutePosition();

  return {
    vertical: [
      {
        guide: Math.round(box.x),
        offset: Math.round(absPos.x - box.x),
        snap: START,
      },
      {
        guide: Math.round(box.x + box.width / 2),
        offset: Math.round(absPos.x - box.x - box.width / 2),
        snap: CENTER,
      },
      {
        guide: Math.round(box.x + box.width),
        offset: Math.round(absPos.x - box.x - box.width),
        snap: END,
      },
    ],
    horizontal: [
      {
        guide: Math.round(box.y),
        offset: Math.round(absPos.y - box.y),
        snap: START,
      },
      {
        guide: Math.round(box.y + box.height / 2),
        offset: Math.round(absPos.y - box.y - box.height / 2),
        snap: CENTER,
      },
      {
        guide: Math.round(box.y + box.height),
        offset: Math.round(absPos.y - box.y - box.height),
        snap: END,
      },
    ],
  };
};

export const getLineGuideStops = (stage: Pamela.Stage, skipShape: any) => {
  const vertical = [ 0, stage.width() / 2, stage.width() ];
  const horizontal = [ 0, stage.height() / 2, stage.height() ];

  stage.find((node: Pamela.Node) => node.id().includes('mle_')).forEach((guideItem) => {
    if (guideItem === skipShape) {
      return;
    }
    const box = guideItem.getClientRect();
    vertical.push([ box.x, box.x + box.width, box.x + box.width / 2 ] as any);
    horizontal.push([ box.y, box.y + box.height, box.y + box.height / 2 ] as any);
  });
  return {
    vertical: vertical.flat(),
    horizontal: horizontal.flat(),
  };
};

export const drawGuides = (layer: Pamela.Layer, guides: any) => {
  guides.forEach((lg: any) => {
    if (lg.orientation === 'H') {
      const line = new Pamela.Line({
        points: [ -layer.getStage().width(), 0, layer.getStage().width(), 0 ],
        stroke: '#2B95D6',
        strokeWidth: 1,
        name: GUIDELINE_NAME,
        perfectDrawEnabled: false,
        draggable: false,
        listening: false,
      });
      layer.add(line);
      line.absolutePosition({
        x: 0,
        y: lg.lineGuide,
      });
    } else if (lg.orientation === 'V') {
      const line = new Pamela.Line({
        points: [ 0, -layer.getStage().height(), 0, layer.getStage().height() ],
        stroke: '#2B95D6',
        strokeWidth: 1,
        name: GUIDELINE_NAME,
        perfectDrawEnabled: false,
        draggable: false,
        listening: false,
      });
      layer.add(line);
      line.absolutePosition({
        x: lg.lineGuide,
        y: 0,
      });
    }
  });
};

export const applyGuidesOnDrag = (layer: Pamela.Layer, stage: Pamela.Stage, e: Pamela.KonvaEventObject<any>) => {
  if (e.target.name() === TRANSFORMER_NAME)
    return;

  layer.find(`.${ GUIDELINE_NAME }`).forEach((l) => l.destroy());

  const lineGuideStops = getLineGuideStops(stage, e.target);
  const itemBounds = getObjectSnappingEdges(e.target);

  const guides = getGuides(lineGuideStops, itemBounds);

  if (!guides.length) {
    return;
  }

  drawGuides(layer, guides);

  const absPos = e.target.absolutePosition();
  guides.forEach((lg) => {
    switch (lg.snap) {
      case START: {
        switch (lg.orientation) {
          case 'V': {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case 'H': {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
        }
        break;
      }
      case CENTER: {
        switch (lg.orientation) {
          case 'V': {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case 'H': {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
        }
        break;
      }
      case END: {
        switch (lg.orientation) {
          case 'V': {
            absPos.x = lg.lineGuide + lg.offset;
            break;
          }
          case 'H': {
            absPos.y = lg.lineGuide + lg.offset;
            break;
          }
        }
        break;
      }
    }
  });
  e.target.absolutePosition(absPos);
};

export const removeGuides = (layer: Pamela.Layer) => {
  layer.find(`.${ GUIDELINE_NAME }`).forEach((l) => l.destroy());
};
