import { MlEditorLayout }             from '../layout';
import React, { useEffect, useState } from 'react';
import classNames                     from 'classnames';
import { currentThemeIsDark }         from '../../../common/utils';
import { LeftPanel }                  from '../panels/LeftPanel';
import { CurrentSelectionPanel }      from '../panels/CurrentSelectionPanel';
import { Editor }                     from '../../../store/observables/Editor';
import { Spinner }                    from '@blueprintjs/core';
import { EditorDrawingArea }          from './EditorDrawingArea';
import { MyLabelLoginDialog }         from '../dialogs/MyLabelLoginDialog';

export interface EditorContentProps {
  editor?: Editor;
}

export const EditorContent = (props: EditorContentProps) => {
  const [mlAuthDialogVisible, setMLAuthDialogVisible] = useState(false);

  useEffect(() => {
    if (props.editor && props.editor.requiresMLAuth)
      setMLAuthDialogVisible(true);
  }, [props.editor?.requiresMLAuth, setMLAuthDialogVisible]);

  return (
    <MlEditorLayout.Content>
      <div className={ classNames('w-64 overflow-y-auto', {
        'bp4-dark bg-bpdark-300': currentThemeIsDark(),
        'bg-bplight-400': !currentThemeIsDark()
      }) }>
        {
          props.editor
          ?
          <LeftPanel editor={ props.editor }/>
          : <Spinner size={ 20 } className="p-6"/>
        }
      </div>
      <div
        className={ 'bg-bplight-100 w-auto overflow-hidden w-4/6 flex items-start' }>
        <EditorDrawingArea editor={ props.editor }/>
      </div>
      <div className={ classNames('w-80 p-2 overflow-y-auto', {
        'bp4-dark bg-bpdark-300': currentThemeIsDark(),
        'bg-bplight-400': !currentThemeIsDark()
      }) }>
        {
          props.editor ?
          <CurrentSelectionPanel editor={ props.editor }/>
                       : <Spinner size={ 20 } className="p-6"/>
        }
      </div>
      {
        props.editor && mlAuthDialogVisible &&
        <MyLabelLoginDialog editor={ props.editor }
                            isOpen={ mlAuthDialogVisible }
                            onClose={ () => {setMLAuthDialogVisible(false);} }
                            onLoginSuccess={ () => setMLAuthDialogVisible(false) }/>
      }
    </MlEditorLayout.Content>
  );
};
