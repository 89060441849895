import {ChildControlFieldProps} from './ShapeControlView';
import {observer} from 'mobx-react';
import {ShapeControlWrapper} from './ShapeControlWrapper';
import {InputGroup} from '@blueprintjs/core';
import {HTMLInputTypeAttribute, useEffect, useState} from 'react';
import {HasEditor} from "../../../common/props";

export interface ControlInputChildConnector<T> extends HasEditor {
    setFieldValue: (value: T) => void;
}

export type ControlFieldChildComponentRenderer = (props: ControlInputChildConnector<string>) => JSX.Element

interface Props extends ChildControlFieldProps<string>, HasEditor {
    type: HTMLInputTypeAttribute,
    /**
     * Function to use for rendering the right element
     * @param props
     */
    rightElementRenderer?: ControlFieldChildComponentRenderer,
}

export const TextBasedControlInput = observer((props: Props) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        setContent(props.observableValue);
    }, [props.labelInfo, props.observableValue]);

    const getRightElement = (): JSX.Element => {
        if (!props.rightElementRenderer) return <div></div>

        return props.rightElementRenderer({
            editor: props.editor,
            setFieldValue: value => {
                setContent(value);
                props.setter(value);
            },
        })
    }

    return (
        <ShapeControlWrapper label={props.label} labelInfo={props.labelInfo}>
            <InputGroup
                type={props.type}
                leftIcon={props.leftIcon}
                rightElement={getRightElement()}
                className="bp4-fill min-w-1/2"
                onChange={(e) => {
                    setContent(e.target.value);
                    props.setter(e.target.value);
                }}
                value={props.type === 'color' ? (content !== 'transparent' ? content : 'white') : content}
            />
        </ShapeControlWrapper>
    );
});
