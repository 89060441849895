import { Editor }                                    from '../../store/observables/Editor';
import {
  ObservableSetterOptions
}                                                    from '../../store/observables/ObservableShape';
import {
  DataControlGroupTypes,
  DataControls
}                                                    from './groups/data/data.controls';
import {
  ControlGroup
}                                                    from './helpers/ControlGroup';
import { GeneralControlGroupTypes, GeneralControls } from './groups/general';

export const defaultSetterOptions = (editor: Editor) => ({
  applyToPamelaShape: true,
  convertFrom: editor.projectData.projectOptions.measureUnit
} as ObservableSetterOptions);

/**
 * Restituisce il control group per il tab dati
 * @param dataCGroupType
 * @param editor
 */
export const getDataControlGroup = (dataCGroupType: DataControlGroupTypes, editor: Editor): ControlGroup => {
  switch (dataCGroupType) {
    case DataControlGroupTypes.CODE:
      return DataControls.codeControlGroup(editor);
    case DataControlGroupTypes.ENCODING:
      return DataControls.encodingControlGroup(editor);
    case DataControlGroupTypes.ADDITIONS:
      return DataControls.additionalControls(editor);
    case DataControlGroupTypes.TEXT_CONTENT:
      return DataControls.simpleTextControls(editor);
    case DataControlGroupTypes.VAR_NAME:
      return DataControls.variableNameControls(editor);
    default:
      throw new Error(`Gruppo di controllo dati ${ dataCGroupType } non gestito`);
  }
};

/**
 * Restituisce il control group generale per le proprietà da modificare
 * @param controlGroupType
 * @param editor
 */
export const getGeneralControlGroup = (controlGroupType: GeneralControlGroupTypes, editor: Editor) => {
  switch (controlGroupType) {
    case GeneralControlGroupTypes.POSITION:
      return GeneralControls.positionControlGroup(editor);

    case GeneralControlGroupTypes.ROTATION:
      return GeneralControls.rotationControlGroup(editor);

    case GeneralControlGroupTypes.DIMENSIONS:
      return GeneralControls.dimensionsControlGroup(editor);

    case GeneralControlGroupTypes.RADIUS:
      return GeneralControls.circleControlGroup(editor);

    case GeneralControlGroupTypes.COLOR:
      return GeneralControls.fillControlGroup(editor);

    case GeneralControlGroupTypes.STROKE:
      return GeneralControls.strokeControlGroup(editor);

    case GeneralControlGroupTypes.LAYERS:
      return GeneralControls.layerControlGroup(editor);

    case GeneralControlGroupTypes.TEXT:
      return GeneralControls.generalTextControls(editor);

    case GeneralControlGroupTypes.FONT:
      return GeneralControls.fontControls(editor);

    case GeneralControlGroupTypes.RICH_TEXT:
      return GeneralControls.richTextGenerals(editor);

    case GeneralControlGroupTypes.STYLE:
      return GeneralControls.fontStyleControls(editor);

    case GeneralControlGroupTypes.ALIGNMENT:
      return GeneralControls.alignmentControls(editor);

    case GeneralControlGroupTypes.COLORS:
      return GeneralControls.backgroundColorControls(editor);

    case GeneralControlGroupTypes.ROW:
      return GeneralControls.lineControls(editor);

    default:
      throw new Error(`Gruppo di controllo ${ controlGroupType } non gestito`);
  }
};

